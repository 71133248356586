import React, { useEffect, useState } from "react";
import { Avatar, Button, Form, Input, Modal } from "antd";
import { Supplier } from "../../../types/SupplierTypes";
import { useUserState } from "../../../common/UserContext";
import { isSuperAdmin } from "../../../types/UserGroup";

interface SupplierEditModalProps {
    open: boolean;
    supplier: Supplier;
    onCancel: () => void;
    onOk: (form: Supplier) => void;
}

export const SupplierEditModal: React.FC<SupplierEditModalProps> = ({
    supplier,
    open,
    onCancel,
    onOk,
}) => {
    const { me } = useUserState();
    const [form, setForm] = useState({} as Supplier);

    useEffect(() => {
        setForm(supplier);
    }, [supplier]);

    return (
        <Modal
            title={form.name}
            open={open}
            onCancel={onCancel}
            footer={
                <>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={() => onOk(form)} type="primary">
                        Confirm
                    </Button>
                </>
            }
        >
            <Form>
                <Form.Item label="Description">
                    <Input.TextArea
                        rows={4}
                        value={form.description}
                        onChange={(e) =>
                            setForm((old) => ({
                                ...old,
                                description: e.target.value,
                            }))
                        }
                    ></Input.TextArea>
                </Form.Item>
                {isSuperAdmin(me.roles || []) ? (
                    <>
                        <Form.Item label="Name">
                            <Input
                                value={form.name}
                                onChange={(e) =>
                                    setForm((old) => ({
                                        ...old,
                                        name: e.target.value,
                                    }))
                                }
                            ></Input>
                        </Form.Item>
                        <Form.Item label="Logo">
                            <Input
                                value={form.logo}
                                addonAfter={<Avatar shape="square" src={form.logo || ""} />}
                                onChange={(e) =>
                                    setForm((old) => ({
                                        ...old,
                                        logo: e.target.value,
                                    }))
                                }
                            ></Input>
                        </Form.Item>
                        <Form.Item label="Symbol">
                            <Input
                                value={form.symbol}
                                onChange={(e) =>
                                    setForm((old) => ({
                                        ...old,
                                        symbol: e.target.value,
                                    }))
                                }
                            ></Input>
                        </Form.Item>
                    </>
                ) : null}
            </Form>
        </Modal>
    );
};
