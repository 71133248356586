import { Select } from "antd";
import { useState } from "react";
import { loadUserGroupSuggestion } from "../../common/api/UserClient";
import { User } from "../../types/UserGroup";
import { handleError } from "../ErrorHandling";
import { UserCard } from "./UserCard";
import { UserImage } from "./UserImage";

interface UserSelectProps {
    userId?: number;
    onSelect?: (userId: number) => void;
}

export const UserSelect: React.FC<UserSelectProps> = ({ onSelect, userId }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([] as User[]);
    const [editing, setEditing] = useState(false);

    const searchUser = async (query: string) => {
        setLoading(true);
        try {
            const res = await loadUserGroupSuggestion(query, "u");
            setOptions(res);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {editing ? (
                <Select
                    showSearch
                    loading={loading}
                    filterOption={false}
                    options={options.map((v) => {
                        return {
                            value: v.id,
                            label: <UserImage size={24} user={v} />,
                        };
                    })}
                    onSelect={(value: number) => {
                        onSelect?.(value);
                        setEditing(false);
                    }}
                    onSearch={(e) => {
                        searchUser(e);
                    }}
                    placeholder={"Search"}
                />
            ) : (
                <UserCard size={32} userId={userId} onClick={() => setEditing(true)} />
            )}
        </>
    );
};
