import { Row, Col, Table, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { impersonateUser, listAllUsers, manageUser } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";
import MainLayout from "../navigation/MainLayout";
import { User, UserRole } from "../../types/UserGroup";
import { userRoleToTag } from "../user/UserAttributes";
import { UserUpdateModal } from "./components/UserUpdateModal";

const { Column } = Table;

export const UserImpersonationView: React.FC = () => {
    const [data, setData] = useState<User[]>([]);
    const [user, setUesr] = useState({} as User);
    const [showUpdate, setShowUpdate] = useState(false);
    const [loading, setLoading]: any[] = useState(true);

    const refreshUsers = async () => {
        setLoading(true);
        try {
            const users = await listAllUsers();
            setData(users);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        refreshUsers();
    }, []);

    const handleManage = (user: User) => {
        setUesr(user);
        setShowUpdate(true);
    };

    const handleUpdate = async (user: User) => {
        try {
            await manageUser(user);
            await refreshUsers();
        } catch (error) {
            handleError(error);
        } finally {
            setShowUpdate(false);
        }
    };

    const userTable = (
        <Table dataSource={data} loading={loading} rowKey="vendorKey">
            <Column title="User Name" sorter={true} dataIndex="name" key="name" />
            <Column
                title="Orgnization Name"
                dataIndex="organization.name"
                key="orgnizatioName"
                render={(_, row: any) => row.organization.name}
            />
            <Column title="Email" sorter={true} dataIndex="email" key="email" />
            <Column
                title="Roles"
                dataIndex="roles"
                render={(value: UserRole[]) => {
                    return value ? value.map((role: UserRole) => userRoleToTag(role)) : "";
                }}
            />
            <Column
                title="Impersonate"
                sorter={true}
                dataIndex="impersonate"
                key="impersonate"
                render={(value, row: User) => {
                    return (
                        <Space>
                            <Button type="primary" onClick={() => handleManage(row)}>
                                Manage
                            </Button>
                            <Button onClick={() => impersonateUser(row.id)}>impersonate</Button>
                        </Space>
                    );
                }}
            />
        </Table>
    );

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <hr />
            <UserUpdateModal
                user={user}
                open={showUpdate}
                onCancel={() => setShowUpdate(false)}
                onOk={handleUpdate}
            />
            <Row justify={"center"}>
                <Col span={24}>{userTable}</Col>
            </Row>
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"impersonation"} collapsed={true} />;
};
