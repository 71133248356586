import { DashboardVendorTimeAggregate } from "../../types/OrderFormTypes";
import { TimeUnit } from "../../types/TimeTypes";
import { Vendor } from "../../types/VendorTypes";
import { safeGET } from "./SafeClient";

export function getDashboardData(
    timeUnit: TimeUnit,
    vendors: Vendor[],
    startDate: Date,
    endDate: Date,
    abort?: any
): Promise<DashboardVendorTimeAggregate> {
    const term =
        vendors !== undefined && vendors.length > 0
            ? "&" + vendors.map((v) => `vendorKey[]=${encodeURIComponent(v.vendorKey)}`).join("&")
            : "";
    return safeGET(
        `/api/dashboard/aggregate/${timeUnit}?startDate=${JSON.stringify(startDate).replaceAll(
            '"',
            ""
        )}&endDate=${JSON.stringify(endDate).replaceAll('"', "")}${term}`,
        abort
    );
}
