import { Breadcrumb, Card, Col, Row, Space, Button, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { MatchingArgunment } from "../../common/UtilityInterfaces";
import { CommentsPanel } from "../comments/CommentsPanel";
import { MessageOutlined } from "@ant-design/icons";
import { TagPanel } from "../tag/TagPanel";
import MainLayout from "../navigation/MainLayout";
import { Typography } from "antd";
import { getVendors } from "../../common/api/VendorClient";
import { Vendor } from "../../types/VendorTypes";
import { ResourceType } from "../../types/ResourceType";
import { OrderFormMiniPanel } from "../orderform/components/OrderFormMiniPanel";
import { OrderFormStatus } from "../../types/OrderFormTypes";
import { Supplier } from "../../types/SupplierTypes";
import { getSupplierByName } from "../../common/api/SupplierClient";
import { getFullUrl } from "../../common/Url";
import { SvgIcon } from "../../common/components/SvgIcon";
import { CompanyImage } from "../../common/components/CompanyImage";

const { Title } = Typography;

interface VendorParams {
    vendorKey: string;
}

export const VendorDetails: React.FC<MatchingArgunment<VendorParams>> = () => {
    const params = useParams();
    const [vendorKey] = useState(params.vendorKey);
    const [vendorData, setVendorData] = useState({} as Vendor);
    const [showMessages, setShowMessages] = useState(false);
    const [supplier, setSupplier] = useState<Supplier>();
    const [loading, setLoading] = useState(false);

    const loadSupplier = async (name: string) => {
        const res = await getSupplierByName(name);
        setSupplier(res);
    };
    useEffect(() => {
        setLoading(true);
        getVendors([vendorKey!])
            .then((vendors) => {
                setVendorData(vendors[0]);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    }, [vendorKey]);

    useEffect(() => {
        if (vendorData.name) {
            loadSupplier(vendorData.name);
        }
    }, [vendorData]);

    const messagesDrawer = showMessages ? (
        <>
            <CommentsPanel
                resourceId={vendorData.id}
                resourceType={ResourceType.Vendor}
                onClose={() => setShowMessages(false)}
            />
        </>
    ) : (
        <></>
    );

    const toolPanel = (
        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
            <Row justify="start" align="middle">
                <Col span={24}>
                    {vendorData.id ? (
                        <TagPanel
                            resourceId={vendorData.id}
                            resourceType={ResourceType.Vendor}
                            readOnly={false}
                        />
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>
        </div>
    );

    const toolbox = (
        <>
            <Space size={10}>
                <Button
                    size={"small"}
                    onClick={() => {
                        setShowMessages(true);
                    }}
                >
                    <MessageOutlined />
                </Button>
            </Space>
        </>
    );

    const breadcrumb = (
        <Breadcrumb key="orderBread" separator=">">
            <Breadcrumb.Item key={`vendor-bread`}>
                <Link to={"/app/vendor"} key="vendor">
                    Vendor
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key={`vendor-bread`}>
                <Link
                    to={`/app/vendor/${vendorData.vendorKey}`}
                    key={`vendor-${vendorData.vendorKey}`}
                >
                    {vendorData.name}
                </Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    );

    const contentPanel = (
        <Row gutter={[8, 8]}>
            <Col span={24}>{toolPanel}</Col>
            <Col span={16}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Card>
                            <Row align="middle">
                                <Col flex="70px">
                                    <CompanyImage
                                        logo={vendorData.hero || supplier?.logo}
                                        name={vendorData.name}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <Title level={3}>
                                        <a href={vendorData.url || ""}>{vendorData.name}</a>
                                    </Title>
                                    <Space>
                                        {supplier?.website && (
                                            <a
                                                href={getFullUrl(supplier.website)}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ display: "inline-flex" }}
                                            >
                                                <SvgIcon name="Web"></SvgIcon>
                                            </a>
                                        )}
                                        {supplier?.linkedIn && (
                                            <a
                                                href={supplier.linkedIn}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ display: "inline-flex" }}
                                            >
                                                <SvgIcon name="Linkedin"></SvgIcon>
                                            </a>
                                        )}
                                        {supplier?.twitter && (
                                            <a
                                                href={supplier.twitter}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ display: "inline-flex" }}
                                            >
                                                <SvgIcon name="Twitter"></SvgIcon>
                                            </a>
                                        )}
                                        {supplier?.id && (
                                            <Link
                                                to={`/app/supplier/${supplier.id}`}
                                                style={{ display: "inline-flex" }}
                                            >
                                                <SvgIcon name="briefs"></SvgIcon>
                                            </Link>
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="70px"></Col>
                                <Col flex="auto">
                                    <span>{vendorData.description}</span>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Card></Card>
            </Col>
            <Col span={12}>
                {vendorData.id ? (
                    <OrderFormMiniPanel
                        title={"Active Orders"}
                        vendorKey={vendorData.vendorKey}
                        filterStatus={[
                            OrderFormStatus.EXPIRATION_NOT_SET,
                            OrderFormStatus.RENEWAL_SCHEDULED,
                            OrderFormStatus.OVERDUE,
                        ]}
                    />
                ) : (
                    <></>
                )}
            </Col>
            <Col span={12}>
                {vendorData.id ? (
                    <OrderFormMiniPanel
                        title={"Resolved orders"}
                        vendorKey={vendorData.vendorKey}
                        filterStatus={[OrderFormStatus.RENEWED, OrderFormStatus.WILL_NOT_RENEW]}
                    />
                ) : (
                    <></>
                )}
            </Col>
        </Row>
    );

    const content = (
        <div style={{ paddingTop: 10 }}>
            {messagesDrawer}
            <Skeleton loading={loading}>
                <Row key="orderContent">
                    <Col span={24}></Col>
                    <Col span={24}>
                        <Card bodyStyle={{ paddingTop: 10 }}>
                            <Row key="orderBread">
                                <Col span={22}>{breadcrumb}</Col>
                                <Col span={2}>
                                    <div style={{ textAlign: "right" }}>{toolbox}</div>
                                </Col>
                            </Row>
                            {contentPanel}
                        </Card>
                    </Col>
                </Row>
            </Skeleton>
        </div>
    );
    return <MainLayout mainPanel={<>{content}</>} selected={"vendors"} collapsed={true} />;
};
