import React from "react";
import { Col, Row, Space, Typography } from "antd";
import NumberFormat from "react-number-format";
import { ReactComponent as WebSvg } from "../../../assets/icons/svg/Web.svg";
import { ReactComponent as LinkedinSvg } from "../../../assets/icons/svg/Linkedin.svg";
import { ReactComponent as TwitterSvg } from "../../../assets/icons/svg/Twitter.svg";
import { SupplierWithEmployee } from "../../../types/SupplierTypes";
import { roundToTwoDecimals } from "../../../common/Number";
import { ESGScore } from "./ESGScore";
import { SupplierScore } from "./SupplierScore";
import { CompanyImage } from "../../../common/components/CompanyImage";

export const SupplierProfile: React.FC<Partial<SupplierWithEmployee>> = ({
    name = "",
    logo = "",
    address = "",
    esgScore = 0,
    currentEmployee,
    employeeDifference = 0,
    description,
    website,
    linkedIn,
    twitter,
}) => {
    const home = website?.startsWith("http") ? website : `https://www.${website}`;

    const socialNetworks = [
        {
            url: home,
            testid: "supplier-profile-website",
            icon: <WebSvg width={16} height={16} />,
        },
        {
            url: linkedIn,
            testid: "supplier-profile-linkedin",
            icon: <LinkedinSvg width={16} height={16} />,
        },
        {
            url: twitter,
            testid: "supplier-profile-twitter",
            icon: <TwitterSvg width={16} height={16} />,
        },
    ];

    return (
        <>
            <Row gutter={56}>
                <Col span={6}>
                    <Space align="start">
                        <CompanyImage logo={logo} name={name} />

                        <div>
                            <div
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: 1.5,
                                }}
                            >
                                {name}
                            </div>
                            <Typography.Link
                                style={{
                                    display: "inline-block",
                                    fontSize: 12,
                                    lineHeight: 1,
                                }}
                                href={`https://maps.google.com/?q=${encodeURIComponent(address)}`}
                                target="_blank"
                            >
                                {address}
                            </Typography.Link>
                        </div>
                    </Space>
                </Col>
                <Col>
                    <Space direction="vertical" size={2}>
                        Supplier score
                        <SupplierScore score={roundToTwoDecimals(esgScore / 20)} />
                    </Space>
                </Col>
                <Col>
                    <Space direction="vertical" size={2}>
                        ESG score
                        <ESGScore score={esgScore} />
                    </Space>
                </Col>
                <Col>
                    <Space direction="vertical" size={2}>
                        Number of employees
                        <Space>
                            <NumberFormat
                                value={currentEmployee}
                                style={{ color: "#52C41A" }}
                                displayType="text"
                                thousandSeparator
                            ></NumberFormat>
                            {`${roundToTwoDecimals(employeeDifference * 100)}% Increase`}
                        </Space>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        {socialNetworks.map((item) => {
                            return item.url ? (
                                <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    data-testid={item.testid}
                                    key={item.testid}
                                >
                                    {item.icon}
                                </a>
                            ) : null;
                        })}
                    </Space>
                </Col>
            </Row>
            <div style={{ marginTop: 16, color: "#666" }}>{description}</div>
        </>
    );
};
