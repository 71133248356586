import { Avatar, Space, Typography } from "antd";
import React from "react";
import { User } from "../../types/UserGroup";

interface UserImageProps {
    size?: number;
    user?: User;
    onClick?: () => void;
}

export const UserImage: React.FC<UserImageProps> = ({ user, size = 48, onClick }) => {
    return (
        <Space onClick={onClick}>
            <Avatar size={size} shape="square" src={user?.avatar || "/base-avatar.png"}></Avatar>
            <div>
                <div style={{ color: "#000000", fontWeight: 500 }}>{user?.name}</div>
                <Typography.Link>{user?.email}</Typography.Link>
            </div>
        </Space>
    );
};
