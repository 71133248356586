import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card } from "antd";
import MainLayout from "../navigation/MainLayout";
import { OrderFormScoreHeader } from "./components/OrderFormScoreHeader";
import { OrderFormScoreMainPanel } from "./OrderFormScoreMainPanel";
import { getOrderForm } from "../../common/api/OrderFormClient";
import { OrderForm } from "../../types/OrderFormTypes";

export const OrderFormScoreView: React.FC = () => {
    const params = useParams();
    const [orderForm, setOrderForm] = useState({} as OrderForm);
    const orderFormId = Number(params.orderFormId);

    const refreshOrder = async () => {
        const res = await getOrderForm(Number(params.orderFormId));
        setOrderForm(res.orderForm);
    };

    useEffect(() => {
        refreshOrder();
    }, [orderFormId]);

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <Card>
                <OrderFormScoreHeader orderFormId={orderFormId} orderFormName={orderForm.name} />
                <OrderFormScoreMainPanel
                    orderFormId={orderFormId}
                    orderForm={orderForm}
                    onChange={refreshOrder}
                />
            </Card>
        </div>
    );

    return <MainLayout mainPanel={panel} selected={"newOrder"} collapsed></MainLayout>;
};
