import { Col, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SkuTable } from "../../../common/components/SkuTable";
import { useUserState } from "../../../common/UserContext";
import { isSuperAdmin } from "../../../types/UserGroup";
import { SkuNewModal } from "./SkuPanel";

interface SkuTablePanelProps {
    orderFormId: number;
}

export const SkuTablePanel: React.FC<SkuTablePanelProps> = ({ orderFormId }) => {
    const { me } = useUserState();
    const [showNewForm, setShowNewForm] = useState(false);
    const [reload, setReload] = useState(0);
    return (
        <div>
            <Row
                style={{
                    marginTop: 20,
                    marginBottom: 12,
                }}
            >
                <Col flex={1}>
                    <div
                        style={{
                            fontWeight: 500,
                            color: "#000000",
                        }}
                    >
                        Products (SKU)
                    </div>
                </Col>
                <Col>
                    <Space>
                        {isSuperAdmin(me.roles || []) ? (
                            <Typography.Link onClick={() => setShowNewForm(true)}>
                                New SKU
                            </Typography.Link>
                        ) : null}
                        <Link to="/app/order/new">New order form</Link>
                    </Space>
                </Col>
            </Row>
            <SkuNewModal
                showForm={showNewForm}
                orderFormId={orderFormId}
                onClose={() => {
                    setShowNewForm(false);
                }}
                onCreate={() => {
                    setShowNewForm(false);
                    setReload(reload + 1);
                }}
            />
            <SkuTable orderFormId={orderFormId} reload={reload}></SkuTable>
        </div>
    );
};
