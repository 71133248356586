import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, Modal } from "antd";
import { BudgetSetting } from "../../../types/DashboardTypes";

interface ComparisonSettingModalProps {
    open: boolean;
    budget?: BudgetSetting;
    onCancel?: () => void;
    onOk?: (params: BudgetSetting) => void;
}

export const ComparisonSettingModal: React.FC<ComparisonSettingModalProps> = ({
    budget,
    open,
    onCancel,
    onOk,
}) => {
    const [form, setForm] = useState({} as BudgetSetting);

    useEffect(() => {
        if (!budget) return;
        setForm(budget);
    }, [budget]);

    return (
        <Modal
            title="Comparison Setting"
            open={open}
            onCancel={onCancel}
            onOk={() => onOk?.(form)}
            okText="Confirm"
        >
            <Form>
                <Form.Item label="Worst Increase">
                    <InputNumber
                        value={form.worstIncrease}
                        addonAfter="%"
                        onChange={(value) => {
                            setForm({ ...form, worstIncrease: value || 0 });
                        }}
                    ></InputNumber>
                </Form.Item>
                <Form.Item label="Best Decrease">
                    <InputNumber
                        value={form.bestDecrease}
                        addonAfter="%"
                        onChange={(value) => {
                            setForm({ ...form, bestDecrease: value || 0 });
                        }}
                    ></InputNumber>
                </Form.Item>
            </Form>
        </Modal>
    );
};
