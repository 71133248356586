import React, { useState } from "react";
import { Button, Select, Space, Tooltip } from "antd";
import { CommentsPanel } from "../comments/CommentsPanel";
import { Negotiation, NegotiationStatus } from "../../types/NegotiationTypes";
import { ResourceType } from "../../types/ResourceType";
import { negotiationStatusToString } from "./NegotiationStatusTag";
import { Vendor } from "../../types/VendorTypes";
import { AttachmentsPanel } from "../attachments/AttachmentsPanel";
import { SvgIcon } from "../../common/components/SvgIcon";

interface NegotiationDetailToolboxProps {
    negotiation: Negotiation;
    vendor?: Vendor;
    onStatusChange?: (status: NegotiationStatus) => void;
}

export const NegotiationDetailToolbox: React.FC<NegotiationDetailToolboxProps> = ({
    negotiation,
    vendor,
    onStatusChange,
}) => {
    const [showMessages, setShowMessages] = useState(false);
    const [showAttachments, setShowAttachments] = useState(false);

    const messagesDrawer = (
        <>
            {vendor?.id && (
                <CommentsPanel
                    resourceId={vendor.id}
                    visible={showMessages}
                    resourceType={ResourceType.Vendor}
                    onClose={() => setShowMessages(false)}
                />
            )}
        </>
    );

    const attachmentsDrawer = (
        <>
            {vendor?.id && (
                <AttachmentsPanel
                    resourceId={vendor.id}
                    open={showAttachments}
                    resourceType={ResourceType.Vendor}
                    onClose={() => setShowAttachments(false)}
                />
            )}
        </>
    );
    return (
        <>
            {messagesDrawer}
            {attachmentsDrawer}
            <Space size={10}>
                <Select value={negotiation.status} onSelect={onStatusChange}>
                    {Object.values(NegotiationStatus).map((status) => {
                        return (
                            <Select.Option value={status}>
                                {negotiationStatusToString(status)}
                            </Select.Option>
                        );
                    })}
                </Select>

                <Button
                    type="link"
                    size={"small"}
                    onClick={() => {
                        setShowMessages(true);
                    }}
                    icon={
                        <Tooltip title={"Show comments"}>
                            <SvgIcon name="message01" />
                        </Tooltip>
                    }
                ></Button>
                <Button
                    type="link"
                    size={"small"}
                    onClick={() => {
                        setShowAttachments(true);
                    }}
                    icon={
                        <Tooltip title={"Show attachments"}>
                            <SvgIcon name="document" />
                        </Tooltip>
                    }
                ></Button>
            </Space>
        </>
    );
};
