import React from "react";
import { Col, Row, Table } from "antd";
import { Link } from "react-router-dom";
import { CompanyImageCell } from "../../../common/components/CompanyImageCell";
import { roundToTwoDecimals } from "../../../common/Number";
import { ReactComponent as NegotiationIcon } from "../../../assets/icons/svg/group.svg";
import { ReactComponent as BriefsIcon } from "../../../assets/icons/svg/briefs.svg";
import { TopSupplier } from "../../../types/DashboardTypes";
import { SupplierInsight } from "../../../types/SupplierTypes";
import { ESGScore } from "../../supplier/components/ESGScore";
import { SimulationScore } from "./SimulationScore";
import { ExpirationCell } from "../../../common/components/ExpirationCell";
import { SvgIcon } from "../../../common/components/SvgIcon";

interface TopSuppliersTableProps {
    data: TopSupplier[];
}

export const TopSuppliresTable: React.FC<TopSuppliersTableProps> = ({ data }) => {
    const columns = [
        {
            title: "Company name",
            dataIndex: "name",
            key: "Name",
            render: (value: string, row: TopSupplier) => {
                return value ? (
                    <CompanyImageCell
                        name={value}
                        logo={row.logo}
                        domain={row.website}
                        link={`/app/vendor/${row.vendorKey}`}
                        linkedin={row.linkedIn}
                        twitter={row.twitter}
                    />
                ) : (
                    <CompanyImageCell name={row.vendorName || ""} />
                );
            },
        },
        {
            title: "Contract score",
            dataIndex: "orderScore",
            key: "orderScore",
            render: (value: number, row: TopSupplier) => {
                return (
                    <Row wrap={false}>
                        <Col flex={1}>
                            <SimulationScore score={roundToTwoDecimals(value || 0)} />
                        </Col>
                        <Col>
                            <Link to={`/app/order/score/${row.orderFormId}`}>
                                <SvgIcon name="outlink" size={15} />
                            </Link>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: "Expiration",
            dataIndex: "expirationDate",
            key: "expirationDate",
            render: (value: string | undefined, row: TopSupplier) => {
                return <ExpirationCell date={value} orderFormId={row.orderFormId} />;
            },
        },
        {
            title: "ESG score",
            dataIndex: "esgScore",
            key: "EsgScore",
            render: (value?: number) => {
                return <ESGScore score={value || 0} />;
            },
        },
        {
            title: "Simulation",
            dataIndex: "negotiationId",
            key: "NegotiationId",
            render: (value: number, row: TopSupplier) => {
                const suffix = value ? `/${value}` : "";
                return (
                    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                        <div style={{ flex: 1 }}>
                            <SimulationScore score={roundToTwoDecimals(row.simulationScore || 0)} />
                        </div>
                        <Link to={`/app/negotiations${suffix}`} style={{ display: "inline-flex" }}>
                            <NegotiationIcon width={15} height={15} fill="currentColor" />
                        </Link>
                    </div>
                );
            },
        },
        {
            title: "Buyer brief",
            dataIndex: "id",
            key: "Id",
            render: (value?: number) => {
                const suffix = value ? `/${value}` : "";
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Link to={`/app/supplier${suffix}`} style={{ display: "inline-flex" }}>
                            <BriefsIcon width={15} height={15} fill="currentColor" />
                        </Link>
                    </div>
                );
            },
        },
        {
            title: "Suggestions",
            dataIndex: "insights",
            key: "Insights",
            render: (value?: SupplierInsight[]) => {
                return (
                    <ul style={{ paddingLeft: "1rem", marginBottom: 0 }}>
                        {value?.slice(0, 2).map((item) => (
                            <li key={item.id}>
                                <span style={{ fontWeight: 500 }}>{item.title}: </span>
                                {item.content}
                            </li>
                        ))}
                    </ul>
                );
            },
        },
    ];

    return <Table dataSource={data} rowKey="vendorName" columns={columns} pagination={false} />;
};
