import React from "react";
import { Col, Row, Space, Typography } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { ProgressWithInnerLabel } from "../../../common/components/ProgressWithInnerLabel";

type Analysis = {
    name: string;
    value: number;
    label?: string;
};

interface SentimentAnalysisProps {
    analyses?: Analysis[];
    onClick?: () => void;
}

export const SentimentAnalysis: React.FC<SentimentAnalysisProps> = ({ analyses, onClick }) => {
    return (
        <div>
            <Row style={{ marginBottom: 12 }}>
                <Col flex={1}>
                    <Space>
                        <span style={{ color: "#000000", fontWeight: 500 }}>
                            Sentiment analysis
                        </span>
                        <QuestionCircleFilled style={{ color: "#1890FF" }}></QuestionCircleFilled>
                    </Space>
                </Col>
                <Col>
                    <Typography.Link onClick={onClick}>Update</Typography.Link>
                </Col>
            </Row>
            <Space direction="vertical" style={{ width: "100%" }} size={12}>
                {analyses?.map((item) => {
                    return (
                        <Row gutter={20} key={item.name}>
                            <Col span={10} style={{ fontSize: 12, color: "#404040" }}>
                                {item.name}
                            </Col>
                            <Col span={14}>
                                <ProgressWithInnerLabel
                                    percent={item.value}
                                    format={item.label ? () => item.label : undefined}
                                />
                            </Col>
                        </Row>
                    );
                })}
            </Space>
        </div>
    );
};
