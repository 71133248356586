import React, { useEffect, useState } from "react";
import { getAttachemts, getAttachemtUrl } from "../../common/api/AttachmentClient";
import {
    AttachmentsDrawer,
    AttachmentsDrawerProps,
} from "../../common/components/AttachmentsDrawer";
import { handleError } from "../../common/ErrorHandling";
import { Attachment } from "../../types/AttachmentTypes";
import { ResourceType } from "../../types/ResourceType";

interface AttachmentsPanelProps extends AttachmentsDrawerProps {
    resourceType: ResourceType;
    resourceId: number;
}

export const AttachmentsPanel: React.FC<AttachmentsPanelProps> = ({
    resourceType,
    resourceId,
    ...restProps
}) => {
    const [attachments, setAttachments] = useState<Attachment[]>([]);

    const handleDownload = async (attachment: Attachment) => {
        const res = await getAttachemtUrl(
            { resourceType: attachment.resourceType, resourceId: attachment.resourceId },
            attachment.id
        );
        if (res.url) {
            window.open(res.url, "_blank");
        }
    };

    const loadAttachments = async () => {
        try {
            const attachments = await getAttachemts({
                resourceId,
                resourceType,
            });
            setAttachments(attachments);
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        if (resourceType && resourceId) {
            loadAttachments();
        }
    }, [resourceType, resourceId, restProps.open]);
    return (
        <AttachmentsDrawer
            attachments={attachments}
            width={600}
            onDownload={handleDownload}
            {...restProps}
        />
    );
};
