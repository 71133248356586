import React, { useEffect, useRef, useState } from "react";
import { Button, Carousel, Col, Row, Space, Image } from "antd";
import { GenericModal, GenericModalProps } from "./GenericModal";
import styles from "./UserGuidModal.module.less";
import { useUserState } from "../UserContext";
import { isAdmin } from "../../types/UserGroup";

const STEPS = [
    {
        adminOnly: true,
        name: "Order forms",
        images: [
            {
                desc: "Upload single or multiple order forms, or create new ones.",
                src: "/guide/forms01.png",
            },
            {
                desc: "View past contract deal scores and optimization suggestions.",
                src: "/guide/forms02.png",
            },
            {
                desc: "View optimization suggestions based on procurement and/or legal levers.",
                src: "/guide/forms03.png",
            },
        ],
        hightlight: "nav-forms",
    },
    {
        adminOnly: false,
        name: "Main dashboard",
        images: [
            {
                desc: "View aggregated dashboard on spend.",
                src: "/guide/home01.png",
            },
            {
                desc: "View your top 5 risks/negotiations,",
                src: "/guide/home02.png",
            },
            {
                desc: "Explore industry status heatmaps.",
                src: "/guide/home03.png",
            },
        ],
        hightlight: "nav-dashboard",
    },
    {
        adminOnly: false,
        name: "Negotiation Intelligence",
        images: [
            {
                desc: "Effortlessly identify cost reduction opportunities in contracts.",
                src: "/guide/negotiation01.png",
            },
            {
                desc: "Add new negotiations and simulate contract savings.",
                src: "/guide/negotiation02.png",
            },
            {
                desc: "Simulate deal improvement potential by procurement and legal levers.",
                src: "/guide/negotiation03.png",
            },
        ],
        hightlight: "nav-negotiations",
    },
    {
        adminOnly: false,
        name: "Buyer briefs",
        images: [
            {
                desc: "Unlock detailed insights into prospective business partners.",
                src: "/guide/brief01.png",
            },
            {
                desc: "Add new vendor analysis if it doesn’t exists in the platform.",
                src: "/guide/brief02.png",
            },
            {
                desc: "Receive an instant, comprehensive one-page report for in-depth vendor analysis",
                src: "/guide/brief03.png",
            },
        ],
        hightlight: "nav-briefs",
    },
    {
        adminOnly: false,
        name: "CLM calendar views and User access management",
        images: [
            {
                desc: "Stay ahead with automated text and email notifications for contract renewals.",
                src: "/guide/clm01.png",
            },
            {
                desc: "Foster collaborative contract management with users and groups.",
                src: "/guide/clm02.png",
            },
        ],
        hightlight: "nav-calendar",
    },
];

const getCurrentStep = (idx: number, steps: typeof STEPS) => {
    if (idx > steps.length - 1) {
        return;
    }
    const step = steps[idx];
    return step;
};

const highlightStyle =
    "z-index:9999;background:#ffffff;box-shadow: 0px 0px 10px 5px rgb(24, 144, 255);";

const highlightNavItem = (id?: string) => {
    if (!id) return;
    const el = document.getElementById(id);
    el?.setAttribute("style", highlightStyle);
};

const dishighlightNavItem = (id?: string) => {
    if (!id) return;
    const el = document.getElementById(id);
    el?.removeAttribute("style");
};

interface UserGuideModalProps extends GenericModalProps {
    onSkip?: () => void;
}

export const UserGuideModal: React.FC<UserGuideModalProps> = ({ onSkip, ...restProps }) => {
    const { me } = useUserState();
    const [current, setCurrent] = useState(0);
    const sliderRef = useRef<any>(null);

    const isUserAdmin = isAdmin(me.roles || []);

    const steps = isUserAdmin ? STEPS : STEPS.filter((item) => !item.adminOnly);

    const step = getCurrentStep(current, steps);

    useEffect(() => {
        if (restProps.open) {
            highlightNavItem(step?.hightlight);
            resetIndex();
        } else {
            dishighlightNavItem(step?.hightlight);
            setCurrent(0);
        }
    }, [restProps.open, step]);

    if (!step) {
        return null;
    }

    const title = (
        <>
            <div className={styles.title}>Welcome to Infinity Loop.</div>
            <div className={styles.subtitle}>Take a quick tour and explore all the features.</div>
        </>
    );

    const content = (
        <>
            <div className={styles.section}>{step?.name}</div>
            <Carousel
                autoplay
                autoplaySpeed={5000}
                ref={sliderRef}
                dots={{ className: styles.dots }}
            >
                {step.images.map((img) => (
                    <div key={img.src}>
                        <div className={styles.desc}>{img.desc}</div>
                        <Image src={img.src} preview={false} />
                    </div>
                ))}
            </Carousel>
        </>
    );

    const resetIndex = () => {
        setTimeout(() => {
            sliderRef.current?.goTo(0);
        }, 100);
    };

    const handlePrev = () => {
        dishighlightNavItem(step.hightlight);
        setCurrent(current - 1);
        resetIndex();
    };

    const handleNext = () => {
        dishighlightNavItem(step.hightlight);
        setCurrent(current + 1);
        resetIndex();
    };

    const preButton =
        current > 0 ? (
            <Button type="link" size="large" onClick={handlePrev}>
                Back
            </Button>
        ) : null;

    const isLast = current < steps.length - 1;
    const nextButton = isLast ? (
        <Button type="primary" size="large" onClick={handleNext}>
            Next
        </Button>
    ) : (
        <Button type="primary" size="large" onClick={(e) => restProps.onOk?.(e)}>
            Done
        </Button>
    );

    const footer = (
        <>
            <Row align="middle">
                <Col flex={1} className={styles.tip}>
                    You can find this quick tour in the settings page.
                </Col>
                <Col>
                    <Space>
                        <Button type="link" size="large" onClick={(e) => onSkip?.()}>
                            Skip tour
                        </Button>
                        {preButton}
                        {nextButton}
                    </Space>
                </Col>
            </Row>
        </>
    );
    return (
        <GenericModal {...restProps} width={828} title={title} footer={footer}>
            {content}
        </GenericModal>
    );
};
