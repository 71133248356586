import { Col, Row, Tag } from "antd";
import { Link } from "react-router-dom";
import { formatDateString } from "../../components/date/DateOp";
import { getDayDiff } from "../../types/TimeTypes";
import { SvgIcon } from "./SvgIcon";

interface ExpirationCellProps {
    orderFormId: number;
    date?: string;
}

const daysColorInterval = [
    {
        days: 60,
        color: "#4FBD19",
    },
    {
        days: 30,
        color: "#FF981F",
    },
    {
        days: 0,
        color: "#FD6883",
    },
];

const getColorByDays = (days?: number) => {
    if (typeof days !== "number" || !days)
        return daysColorInterval[daysColorInterval.length - 1].color;
    for (const item of daysColorInterval) {
        if (item.days < days) return item.color;
    }
    return daysColorInterval[daysColorInterval.length - 1].color;
};

export const ExpirationCell: React.FC<ExpirationCellProps> = ({ orderFormId, date }) => {
    const now = new Date();
    const orderDate = date ? new Date(date) : new Date();
    const isPast = orderDate.getTime() < now.getTime();

    const dayDiff = date ? getDayDiff(orderDate, now) : 365;
    const color = isPast ? getColorByDays() : getColorByDays(dayDiff);
    const label = isPast ? formatDateString(orderDate) : `${dayDiff} days to expire`;
    return (
        <Row gutter={8} wrap={false}>
            <Col flex={1}>
                <Tag
                    style={{
                        background: color,
                        color: "#ffffff",
                        fontWeight: 500,
                    }}
                >
                    {label}
                </Tag>
            </Col>
            <Col>
                <Link to={`/app/order/${orderFormId}`}>
                    <SvgIcon name="outlink" size={15} />
                </Link>
            </Col>
        </Row>
    );
};
