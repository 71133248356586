import React, { useEffect, useState } from "react";
import { Col, Input, Row, UploadFile } from "antd";
import { MatchScore } from "../../../common/components/MatchScore";
import { SentimentAnalysis } from "./SentimentAnalysis";
import { SkuUsage } from "./SkuUsage";
import { UtilizationMeasureModal } from "./UtilizationMeasureModal";
import {
    createOrderFormAnalyses,
    getOrderFormAnalyses,
    getSkus,
    updateOrderForm,
    updateOrderFormAnalyses,
    updateSKUs,
} from "../../../common/api/OrderFormClient";
import { addComment, getComments } from "../../../common/api/CommentClient";
import { handleError } from "../../../common/ErrorHandling";
import {
    OrderForm,
    OrderFormAnalysis,
    OrderFormAnalysisResponse,
    OrderFormSKU,
} from "../../../types/OrderFormTypes";
import { Resource, ResourceType } from "../../../types/ResourceType";
import { AddCommentRequest, Comment } from "../../../types/CommentTypes";
import { addAttachemt } from "../../../common/api/AttachmentClient";

interface UtilizationMeasurePanelProps {
    orderFormId: number;
    vendorId: number;
    utilizationScore?: number;
    onChange?: () => void;
}
export const UtilizationMeasurePanel: React.FC<UtilizationMeasurePanelProps> = ({
    orderFormId,
    vendorId,
    utilizationScore = 0,
    onChange,
}) => {
    const [survey, setSurvey] = useState<OrderFormAnalysisResponse[]>();
    const [skus, setSkus] = useState([] as OrderFormSKU[]);
    const [showSurveyModal, setShowSurveyModal] = useState(false);
    const [reload, setReload] = useState(0);
    const [comment, setComment] = useState("");
    const [commentList, setCommentList] = useState([] as Comment[]);

    const filteredSkus = skus.filter((item) => item.usage);
    const filteredComments = commentList.filter((item) => item.isPositive);

    const resource: Resource = {
        resourceId: vendorId,
        resourceType: ResourceType.Vendor,
    };
    const loadSurvey = async () => {
        try {
            const res = await getOrderFormAnalyses(orderFormId);
            setSurvey(res);
        } catch (error) {
            handleError(error);
        }
    };

    const loadSkus = async () => {
        try {
            const res = await getSkus(orderFormId);
            setSkus(res);
        } catch (error) {
            handleError(error);
        }
    };

    const loadComments = async () => {
        try {
            if (!resource.resourceId) return;
            const res = await getComments(resource);
            setCommentList(res);
        } catch (error) {
            handleError(error);
        }
    };

    const sumbmitSurvey = async (
        surveyList?: OrderFormAnalysisResponse[],
        skus?: OrderFormSKU[],
        score?: number,
        comment?: string,
        files?: UploadFile[]
    ) => {
        const toUpdate = surveyList?.filter((item) => item.id);
        const toCreate = surveyList?.filter((item) => !item.id);
        try {
            if (toUpdate?.length) {
                await updateOrderFormAnalyses(orderFormId, toUpdate as OrderFormAnalysis[]);
            }
            if (toCreate?.length) {
                await createOrderFormAnalyses(orderFormId, toCreate as OrderFormAnalysis[]);
            }

            if (skus?.length) {
                await updateSKUs(orderFormId, skus);
            }

            if (score !== undefined) {
                await updateOrderForm({
                    id: orderFormId,
                    utilizationScore: score,
                } as OrderForm);
            }

            if (comment) {
                await addComment(resource, { comment, resourceId: resource } as AddCommentRequest);
            }
            if (files?.length) {
                await Promise.all(
                    files.map(async (item) => {
                        if (!item.originFileObj) return;
                        await addAttachemt(resource, item.originFileObj);
                    })
                );
            }
        } catch (error) {
            handleError(error);
        } finally {
            setShowSurveyModal(false);
            onChange?.();
            setReload(reload + 1);
        }
    };

    const sendComment = async () => {
        try {
            await addComment(resource, {
                comment: comment,
                resourceId: resource,
            } as AddCommentRequest);

            setComment("");
        } catch (error: any) {
            handleError(error);
        }
    };

    useEffect(() => {
        loadSurvey();
        loadSkus();
        loadComments();
    }, [reload]);

    useEffect(() => {
        loadComments();
    }, [vendorId]);

    return (
        <div style={{ marginTop: 20 }}>
            <div style={{ fontWeight: 500, color: "#000000" }}>Utilization measure</div>
            <div style={{ color: "#404040", marginBottom: 20 }}>
                See if you are getting the most out of particular goods or services.
            </div>
            <Row>
                <Col span={5}>
                    <MatchScore
                        title="Utilization Score"
                        score={`${utilizationScore}/10`}
                    ></MatchScore>
                </Col>
                <Col span={9}>
                    <SkuUsage
                        usage={filteredSkus.length}
                        data={filteredSkus.map((item) => ({
                            value: item.usage,
                            name: item.name,
                        }))}
                    />
                </Col>
                <Col span={10}>
                    <UtilizationMeasureModal
                        open={showSurveyModal}
                        onCancel={() => setShowSurveyModal(false)}
                        sentiment={survey}
                        skus={skus}
                        score={utilizationScore}
                        onOk={sumbmitSurvey}
                    />
                    <div>
                        <SentimentAnalysis
                            onClick={() => setShowSurveyModal(true)}
                            analyses={survey
                                ?.map((item) => ({
                                    name: item.analysis.title,
                                    value:
                                        item.analysis.options.find(
                                            (ele) => ele.id === item.selectedOptionId
                                        )?.weight || 0,
                                }))
                                .concat([
                                    {
                                        name: "Positive comments",
                                        value: commentList.length
                                            ? filteredComments.length / commentList.length
                                            : 0,
                                        label: `${filteredComments.length}/${commentList.length}`,
                                    } as any,
                                ])}
                        />
                        <Input.TextArea
                            placeholder="Add a supplier comment"
                            style={{ marginTop: 16 }}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            onPressEnter={() => sendComment()}
                        ></Input.TextArea>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
