import React from "react";
import { Space } from "antd";
import { SkuUsageChart, SkuUsageChartProps } from "./SkuUsageChart";
import { QuestionCircleFilled } from "@ant-design/icons";

interface SkuUsageProps extends SkuUsageChartProps {
    height?: number;
}

export const SkuUsage: React.FC<SkuUsageProps> = ({ height = 200, ...restProps }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <div>
                <Space>
                    <span style={{ color: "#000000", fontWeight: 500 }}>Actual usage</span>
                    <QuestionCircleFilled style={{ color: "#1890FF" }} />
                </Space>
            </div>
            <div style={{ height }}>
                <SkuUsageChart {...restProps} />
            </div>
            <div style={{ textAlign: "center", color: "#000000", fontWeight: 500 }}>
                Showing SKU utilization
            </div>
        </div>
    );
};
