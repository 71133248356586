import { Card, Drawer, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Skeleton, Comment, Form, Input, Button, Divider } from "antd";
import { formatDetailDate, momentDate } from "../date/DateOp";
import { ResourceType } from "../../types/ResourceType";
import { addComment, getComments } from "../../common/api/CommentClient";
import { AddCommentRequest, Comment as ILComment } from "../../types/CommentTypes";
import { User } from "../../types/UserGroup";
import { getUsers } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";
import Avatar from "../../common/components/Avatar";

const { TextArea } = Input;

interface CommentPanelArguments {
    resourceType: ResourceType;
    resourceId: number;
    visible?: boolean;
    onClose: () => void;
}

interface CommentArguments {
    comment: ILComment;
    replyAction: (comment: ILComment, text: string) => void;
    userRegistry: Map<number, User>;
}

interface CommentEditorArguments {
    onSubmit: (text: string) => void;
    onCancel: () => void;
}

const CommentEditor: React.FC<CommentEditorArguments> = ({ onSubmit, onCancel }) => {
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Spin spinning={loading}>
                <Form.Item>
                    <TextArea
                        rows={4}
                        onChange={(e) => {
                            setText(e.target.value);
                        }}
                        value={text}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        onClick={() => {
                            setLoading(true);
                            onSubmit(text);
                        }}
                        loading={false}
                        type="primary"
                    >
                        Add Comment
                    </Button>
                    &nbsp;
                    <Button
                        danger
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                </Form.Item>
                <Divider />
            </Spin>
        </>
    );
};

const CommentLine: React.FC<CommentArguments> = ({ comment, userRegistry, replyAction }) => {
    const [showReply, setShowReply] = useState(false);

    const replyPanel = showReply ? (
        <CommentEditor
            key={`${comment.id}-${comment.authorId}-${comment.commentDate}-editor`}
            onCancel={() => setShowReply(false)}
            onSubmit={(text) => {
                replyAction(comment, text);
                setShowReply(false);
            }}
        />
    ) : (
        <></>
    );

    const author = userRegistry.get(comment.authorId);
    return (
        <Comment
            key={`${comment.id}-${comment.authorId}-${comment.commentDate}`}
            author={author?.name}
            avatar={
                <Avatar
                    key={`${comment.id}-${comment.authorId}-${comment.commentDate}-avatar`}
                    email={author?.email}
                />
            }
            content={<p>{comment.comment}</p>}
            datetime={formatDetailDate(momentDate(new Date(comment.commentDate)))}
            actions={[
                <span
                    key={`comment-nested-reply-to-${comment.id}`}
                    onClick={() => setShowReply(true)}
                >
                    Reply to
                </span>,
            ]}
        >
            {comment.replies !== undefined ? (
                comment.replies.map((c) => (
                    <CommentLine
                        key={`${comment.id}-${comment.authorId}-${comment.commentDate}-reply-${c.id}`}
                        comment={c}
                        userRegistry={userRegistry}
                        replyAction={replyAction}
                    />
                ))
            ) : (
                <></>
            )}
            {replyPanel}
        </Comment>
    );
};

const collectUsers = (comment: ILComment): number[] => {
    if (comment.replies !== undefined) {
        return comment.replies
            .map((c) => collectUsers(c))
            .reduce((p, v) => p.concat(v), [])
            .concat([comment.authorId]);
    } else {
        return [comment.authorId];
    }
};

export const CommentsPanel: React.FC<CommentPanelArguments> = ({
    resourceId,
    resourceType,
    onClose,
    visible = true,
}) => {
    const [comments, setComments] = useState([] as ILComment[]);
    const [loading, setLoading] = useState(false);
    const [userRegistry, setUserRegistry] = useState({} as Map<number, User>);
    const [showEditor, setShowEditor] = useState(false);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        if (visible) {
            setReload(reload + 1);
        }
    }, [visible]);

    const resource = useMemo(() => {
        return { resourceId: resourceId, resourceType: resourceType };
    }, [resourceId, resourceType]);

    useEffect(() => {
        setLoading(true);
        getComments(resource)
            .then(async (_comments) => {
                const userIds = _comments
                    .map((c) => collectUsers(c))
                    .reduce((p, v) => p.concat(v), [])
                    .filter(function (elem, index, self) {
                        return index === self.indexOf(elem);
                    });
                const users = await getUsers(userIds);
                const _userRegistry = new Map(users.map((u) => [u.id, u]));
                setUserRegistry(_userRegistry);
                setComments(_comments);
                //get user Ids
            })
            .finally(() => {
                setLoading(false);
            });
    }, [resource, reload]);

    const sendReply = async (comment: ILComment, text: string) => {
        try {
            const reply = await addComment(resource, {
                comment: text,
                resourceId: resource,
                replyTo: comment.id,
            } as AddCommentRequest);
            if (comment.replies === undefined) {
                comment.replies = [];
            }
            comment.replies.push(reply);
            setComments(comments.concat([]));
        } catch (error: any) {
            handleError(error, "Failed to send your reply");
        }
    };
    const sendComment = async (text: string) => {
        try {
            const comment = await addComment(resource, {
                comment: text,
                resourceId: resource,
            } as AddCommentRequest);

            setComments(comments.concat([comment]));
        } catch (error: any) {
            handleError(error, "Failed to send your reply");
        }
    };

    const commentLines = comments.map((c) => (
        <CommentLine
            key={`${c.id}-${c.authorId}-${c.commentDate}-comment`}
            comment={c}
            userRegistry={userRegistry}
            replyAction={sendReply}
        />
    ));

    const editor = showEditor ? (
        <CommentEditor
            onCancel={() => {
                setShowEditor(false);
            }}
            onSubmit={(text) => {
                sendComment(text);
                setShowEditor(false);
            }}
        />
    ) : (
        <></>
    );

    const content = (
        <Card>
            <div style={{ width: "100%", textAlign: "right" }}>
                {showEditor ? (
                    ""
                ) : (
                    <>
                        <a
                            href="http://example.com"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowEditor(true);
                            }}
                        >
                            Add Comment
                        </a>
                    </>
                )}
            </div>
            <Skeleton loading={loading}>{showEditor ? editor : ""}</Skeleton>
            {commentLines}
        </Card>
    );

    return (
        <Drawer title="Comments" placement="right" width={640} onClose={onClose} visible={visible}>
            {content}
        </Drawer>
    );
};
