import { MinusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Table,
    Typography,
} from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { deleteSKU, getSkus, newSKU, updateSKU } from "../../../common/api/OrderFormClient";
import { handleError } from "../../../common/ErrorHandling";
import { OrderFormSKU } from "../../../types/OrderFormTypes";

interface SkuNewModalArguments {
    showForm: boolean;
    orderFormId: number;
    onCreate: (sku: OrderFormSKU) => void;
    onClose?: () => void;
}
export const SkuNewModal: React.FC<SkuNewModalArguments> = ({
    showForm,
    orderFormId,
    onClose,
    onCreate,
}) => {
    const [orderFormSKU, setOrderFormSKU] = useState({} as OrderFormSKU);

    const createOrderFormFn = async () => {
        try {
            console.log("created a new copy");
            const sku = await newSKU(orderFormId, orderFormSKU);
            onCreate(sku);
            // const ofr = await createOrderForm(orderForm);as
        } catch (error: any) {
            handleError(error);
        }
    };

    const panel = (
        <Modal
            title="New Order Form SKU"
            visible={showForm}
            onOk={() => {
                createOrderFormFn();
            }}
            onCancel={() => {
                if (onClose) onClose();
            }}
        >
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
                size={"small" as SizeType}
            >
                <Form.Item label="Name">
                    <Input
                        value={orderFormSKU.name}
                        onChange={(e) => {
                            orderFormSKU.name = e.target.value;
                            setOrderFormSKU({ ...orderFormSKU });
                        }}
                    />
                </Form.Item>
                <Form.Item label="SKU">
                    <Input
                        value={orderFormSKU.sku}
                        onChange={(e) => {
                            orderFormSKU.sku = e.target.value;
                            setOrderFormSKU({ ...orderFormSKU });
                        }}
                    />
                </Form.Item>

                <Form.Item label="Quantity">
                    <NumberFormat
                        className={"ant-input"}
                        value={orderFormSKU.quantity}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={""}
                        onValueChange={(value) => {
                            orderFormSKU.quantity = value.floatValue || 0;
                            setOrderFormSKU({ ...orderFormSKU });
                        }}
                    />
                </Form.Item>
                <Form.Item label="value">
                    <NumberFormat
                        className={"ant-input"}
                        value={orderFormSKU.price}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={"$"}
                        onValueChange={(value) => {
                            orderFormSKU.price = value.floatValue || 0;
                            setOrderFormSKU({ ...orderFormSKU });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Total Price">
                    <NumberFormat
                        className={"ant-input"}
                        value={orderFormSKU.totalPrice}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={"$"}
                        onValueChange={(value) => {
                            orderFormSKU.totalPrice = value.floatValue || 0;
                            setOrderFormSKU({ ...orderFormSKU });
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
    return panel;
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: OrderFormSKU;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

interface SKUPanelArgs {
    orderFormId: number;
    disabled?: boolean;
}

const SkuPanel: React.FC<SKUPanelArgs> = ({ orderFormId, disabled }) => {
    const [skus, setSkus] = useState([] as OrderFormSKU[]);
    const [editingKey, setEditingKey] = useState(0);
    const [saveCount, setSaveCount] = useState(0);
    const [showNewForm, setShowNewForm] = useState(false);
    const [form] = Form.useForm();
    const isEditing = (record: OrderFormSKU) => record.id === editingKey;
    const edit = (record: OrderFormSKU) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id!);
    };

    const doDelete = async (record: OrderFormSKU) => {
        await deleteSKU(record);
        setSaveCount(saveCount + 1);
    };
    const save = async (id: number) => {
        try {
            const row = (await form.validateFields()) as OrderFormSKU;

            await updateSKU({ ...row, orderFormId: orderFormId, id: id });
            //this should update the in-memory copy of the update record rather than updating every record
            setEditingKey(0);
            setSaveCount(saveCount + 1);
        } catch (errInfo) {
            handleError(errInfo);
        }
    };
    const cancel = async () => {
        setEditingKey(0);
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
            inputType: "string",
            editable: true,
        },
        {
            title: "Sku",
            dataIndex: "sku",
            key: "sku",
            sorter: true,
            inputType: "string",
            editable: true,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            sorter: true,
            inputType: "number",
            editable: true,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            sorter: true,
            inputType: "number",
            editable: true,
        },
        {
            title: "Total Price",
            dataIndex: "totalPrice",
            key: "totalPrice",
            sorter: true,
            inputType: "number",
            editable: true,
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_: any, record: OrderFormSKU) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <Typography.Link>Cancel</Typography.Link>
                        </Popconfirm>
                    </span>
                ) : (
                    <>
                        {" "}
                        <Typography.Link disabled={editingKey !== 0} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                        <Divider type="vertical" />
                        <Typography.Link
                            disabled={editingKey !== 0}
                            onClick={() => doDelete(record)}
                        >
                            Delete
                        </Typography.Link>
                    </>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: OrderFormSKU) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    useEffect(() => {
        const fn = async () => {
            const remoteSkus = await getSkus(orderFormId);
            setSkus(remoteSkus);
        };
        try {
            fn();
        } catch (error: any) {
            handleError(error);
        }
    }, [saveCount]);

    return (
        <Card
            title="Products (SKU)"
            size={"small"}
            extra={
                disabled
                    ? [<MinusOutlined />]
                    : [
                          <Button
                              type={"dashed"}
                              size={"small"}
                              onClick={() => {
                                  setShowNewForm(true);
                              }}
                          >
                              <PlusSquareOutlined key="addRecipient" />
                          </Button>,
                      ]
            }
        >
            {
                <SkuNewModal
                    showForm={showNewForm}
                    orderFormId={orderFormId}
                    onClose={() => {
                        setShowNewForm(false);
                    }}
                    onCreate={() => {
                        setShowNewForm(false);
                        setSaveCount(saveCount + 1);
                    }}
                />
            }
            <div style={{ height: "350px" }}>
                <Form form={form} component={false}>
                    <Table
                        size={"small"}
                        style={{ scrollbarWidth: "thin" }}
                        dataSource={skus}
                        columns={mergedColumns}
                        bordered
                        pagination={false}
                        scroll={{ y: 300 }}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                    />
                </Form>
            </div>
        </Card>
    );
};
export default SkuPanel;
