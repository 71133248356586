import React, { MouseEventHandler, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import {
    Button,
    Col,
    Input,
    message,
    Row,
    Space,
    Spin,
    Upload,
    UploadProps,
    UploadFile,
} from "antd";
import { SvgIcon } from "../../../common/components/SvgIcon";
import styles from "./UtilizationMeasureModalFooter.module.less";

interface UtilizationMeasureModalFooterProps {
    placeholder?: string;
    onCancel?: MouseEventHandler<HTMLElement>;
    onOk?: (comment?: string, files?: UploadFile[]) => Promise<void>;
}

export const UtilizationMeasureModalFooter: React.FC<UtilizationMeasureModalFooterProps> = ({
    placeholder = "Type or tell me anything about this product",
    onCancel,
    onOk,
}) => {
    const [loading, setLoading] = useState(false);
    const [inputText, setInputText] = useState("");
    const [files, setFiles] = useState<UploadFile[]>([]);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition();

    const hanldeListen = () => {
        if (!browserSupportsSpeechRecognition) {
            return message.error("Browser doesn't support speech recognition");
        }
        if (!isMicrophoneAvailable) {
            return message.error("Microphone is not available");
        }
        if (listening) {
            SpeechRecognition.stopListening();
            return;
        }

        if (transcript) resetTranscript();
        if (inputText) setInputText("");
        SpeechRecognition.startListening({ continuous: true, language: "en-US" });
    };

    const handleFileChange: UploadProps["onChange"] = async ({ fileList }) => {
        setFiles(fileList);
    };

    const handleDelete = (file: UploadFile) => {
        const newFiles = files.filter((item) => item.uid !== file.uid);
        setFiles(newFiles);
    };

    const handleSend = async () => {
        setLoading(true);
        await onOk?.(inputText, files);
        setInputText("");
        setFiles([]);
        setLoading(false);
    };

    useEffect(() => {
        setInputText(transcript);
    }, [transcript]);

    useEffect(() => {
        return () => {
            SpeechRecognition.abortListening();
        };
    }, []);

    const attachmentView = (
        <>
            {files.map((file) => (
                <Row className={styles.attachment} align="middle">
                    <Col flex={1}>
                        <Space>
                            <SvgIcon name="document" />
                            {file.name}
                        </Space>
                    </Col>
                    <Col>
                        <Button
                            type="link"
                            onClick={() => handleDelete(file)}
                            icon={<SvgIcon name="trash" />}
                        ></Button>
                    </Col>
                </Row>
            ))}
        </>
    );

    return (
        <>
            <Spin spinning={loading}>
                <Row>
                    <Col flex={1}>
                        <Input
                            size="large"
                            bordered={false}
                            placeholder={placeholder}
                            style={{
                                color: "#C2C9D6",
                            }}
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                        ></Input>
                    </Col>
                    <Col>
                        <Space>
                            <Upload
                                multiple
                                showUploadList={false}
                                beforeUpload={() => false}
                                onChange={handleFileChange}
                                accept=".pdf,.doc,.docx,.xls,.xlsx,csv"
                                fileList={files}
                                maxCount={10}
                            >
                                <Button
                                    type="link"
                                    icon={<SvgIcon name="plus" color="#5AD81D" size={18} />}
                                ></Button>
                            </Upload>
                            <Button
                                className={`${styles.mic}${
                                    listening ? ` ${styles.listening}` : ""
                                }`}
                                type="link"
                                icon={<SvgIcon name="mic" color="#5AD81D" size={18} />}
                                onClick={() => hanldeListen()}
                            ></Button>
                        </Space>
                    </Col>
                </Row>
                {attachmentView}
                <div className={styles.actions}>
                    <Button type="link" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" onClick={handleSend}>
                        Send
                    </Button>
                </div>
            </Spin>
        </>
    );
};
