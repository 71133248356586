import { Row, Col, Table, Button, Space, Segmented } from "antd";
import React, { useEffect, useState } from "react";
import { handleError } from "../../common/ErrorHandling";
import MainLayout from "../navigation/MainLayout";
import { IndustryManageModal } from "./components/IndustryManageModal";
import { Industry } from "../../types/IndustryTypes";
import { createIndustry, getIndustries, updateIndustry } from "../../common/api/IndustryClient";

const { Column } = Table;

export enum Category {
    Sector = "Sector",
    Industry = "Industry",
}

export const IndustryView: React.FC = () => {
    const [data, setData] = useState<Industry[]>([]);
    const [industry, setIndustry] = useState({} as Industry);
    const [category, setCategory] = useState(Category.Sector);
    const [showUpdate, setShowUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    const sectors = data.filter((item) => !item.pid);
    const industries = data.filter((item) => item.pid);
    const tableData = category === Category.Sector ? sectors : industries;

    const refreshTable = async () => {
        setLoading(true);
        try {
            const data = await getIndustries();
            setData(data);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        refreshTable();
    }, []);

    const showManageModal = (data: Industry, isEdit: boolean) => {
        setIsEdit(isEdit);
        setIndustry(data);
        setShowUpdate(true);
    };

    const handleManage = async (data: Industry) => {
        try {
            if (isEdit) {
                await updateIndustry(data);
            } else {
                await createIndustry(data);
            }
            await refreshTable();
        } catch (error) {
            handleError(error);
        } finally {
            setShowUpdate(false);
        }
    };

    const table = (
        <Table dataSource={tableData} loading={loading} rowKey="id">
            <Column title="Name" sorter={true} dataIndex="name" key="name" />
            {category === Category.Sector ? null : (
                <Column
                    title="Sector Name"
                    dataIndex="pid"
                    key="sector"
                    render={(value) => sectors.find((item) => item.id === value)?.name}
                />
            )}
            <Column
                title="Type"
                dataIndex="organization.name"
                key="orgnizatioName"
                render={(_, row: Industry) => (row.pid ? Category.Industry : Category.Sector)}
            />
            <Column
                title="Actions"
                key="actions"
                render={(value, row: Industry) => {
                    return (
                        <Space>
                            <Button onClick={() => showManageModal(row, true)}>Edit</Button>
                        </Space>
                    );
                }}
            />
        </Table>
    );

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <hr />
            <IndustryManageModal
                title={isEdit ? `Update ${category}` : `Create ${category}`}
                industry={industry}
                category={category}
                sectors={sectors}
                open={showUpdate}
                onCancel={() => setShowUpdate(false)}
                onOk={handleManage}
            />
            <Row style={{ padding: 10 }}>
                <Col flex={1}>
                    <Segmented
                        options={Object.values(Category)}
                        value={category}
                        onChange={(value) => setCategory(value as Category)}
                    />
                </Col>
                <Col>
                    <Button type="primary" onClick={() => showManageModal({} as Industry, false)}>
                        Create
                    </Button>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col span={24}>{table}</Col>
            </Row>
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"industry"} collapsed={true} />;
};
