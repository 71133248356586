import { Tag } from "antd";
import { UserRole, UserStatus } from "../../types/UserGroup";

export const userStatusToTag = (status: UserStatus) => {
    switch (status) {
        case UserStatus.ACTIVE:
            return <Tag color={"success"}>ACTIVE</Tag>;
        case UserStatus.WAITING:
            return <Tag color={"processing"}>WAITING</Tag>;
        case UserStatus.DISABLED:
            return <Tag color={"error"}>DISABLED</Tag>;
        default:
            return <></>;
    }
};
export const userRoleToTag = (role: UserRole) => {
    switch (role) {
        case UserRole.ADMIN:
            return <Tag color={"error"}>Admin</Tag>;
        case UserRole.USER:
            return <Tag color={"success"}>User</Tag>;
        case UserRole.SUPER_ADMIN:
            return <Tag color={"error"}>Super Admin</Tag>;
        case UserRole.VIEWER:
            return <Tag color={"warning"}>Viewer</Tag>;
        case UserRole.REVIEWER:
            return <Tag color={"processing"}>Reviewer</Tag>;
        case UserRole.ONBOARDING:
            return <Tag>Onboarding</Tag>;
        case UserRole.POC:
            return <Tag>POC</Tag>;
        default:
            return <></>;
    }
};
