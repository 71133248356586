import { useEffect, useState } from "react";
import { Form, Modal, ModalProps, Select } from "antd";
import { DocumentReview, ReviewStatus } from "../../../types/ReviewTypes";
import { reviewStatusToString } from "../ReviewStatus";

interface ReviewUpdateModalProps extends Omit<ModalProps, "onOk"> {
    review?: DocumentReview;
    onOk?: (orderForm: DocumentReview) => void;
}

export const ReviewUpdateModal: React.FC<ReviewUpdateModalProps> = ({
    review,
    onOk,
    ...restProps
}) => {
    const [reviewCopy, setReviewCopy] = useState<DocumentReview>({} as DocumentReview);

    useEffect(() => {
        review && setReviewCopy(review);
    }, [review]);

    return (
        <Modal
            title="Update Review"
            {...restProps}
            onOk={() => {
                onOk?.(reviewCopy);
            }}
        >
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} layout="horizontal" size="small">
                <Form.Item label="Status">
                    <Select
                        value={reviewCopy.status}
                        onSelect={(value) => {
                            setReviewCopy((old) => ({ ...old, status: value }));
                        }}
                    >
                        {Object.values(ReviewStatus).map((status) => {
                            return (
                                <Select.Option value={status}>
                                    {reviewStatusToString(status)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
