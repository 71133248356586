import React, { useState } from "react";
import { Row, Col } from "antd";
import { Card } from "antd";
import MainLayout from "../navigation/MainLayout";
import { CalendarDashboard } from "./CalendarDashboard";
import { VendorSelectInput } from "../vendor/VendorInput";
import { Vendor } from "../../types/VendorTypes";

export const Calendar: React.FC = () => {
    const [vendors, setVendors] = useState([] as Vendor[]);

    return (
        <MainLayout
            collapsed={true}
            selected={"Calendar"}
            mainPanel={
                <div>
                    <Card>
                        <Row>
                            <Col span={24}>
                                <Card
                                    size="small"
                                    title={"Calendar"}
                                    style={{ height: "100%" }}
                                    bodyStyle={{
                                        height: "100%",
                                        padding: 20,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Row>
                                        <Col flex="auto"></Col>
                                        <Col span={5}>
                                            <div style={{ width: "100%", textAlign: "left" }}>
                                                <VendorSelectInput
                                                    onSelect={(vendors) =>
                                                        setVendors(vendors.concat([]))
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ flexGrow: 1, minHeight: 600 }}>
                                        <Col span={24}>
                                            <CalendarDashboard vendors={vendors} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </div>
            }
        />
    );
};
