import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Skeleton, Space } from "antd";
import { Resource, ResourceType } from "../../types/ResourceType";
import { ActionDropdown } from "./ActionDropdown";
import "./NotificationSetting.less";
import {
    getNotificationsSettingsForResource,
    updateNotificationsSettingsForResource,
} from "../api/NotificationsClient";
import { handleError } from "../ErrorHandling";
import { ResourceNotificationSettings } from "../../types/NotificationTypes";

export interface NotificationSettingProps {
    resourceId: number;
    resourceType: ResourceType;
    disabled?: boolean;
}

interface ActionType {
    key: keyof ResourceNotificationSettings;
    label: string;
    divider?: boolean;
}

export const NotificationSetting: React.FC<NotificationSettingProps> = ({
    resourceId,
    resourceType,
    disabled,
}) => {
    const [open, setOpen] = useState(false);

    const [resource] = useState({
        resourceId: resourceId,
        resourceType: resourceType,
    } as Resource);
    const [settings, setSettings] = useState({} as ResourceNotificationSettings);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const ac = new AbortController();
        try {
            getNotificationsSettingsForResource(resource)
                .then((settings_) => {
                    setSettings(settings_);
                })
                .catch((error: any) => {
                    handleError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error: any) {
            handleError(error);
            setLoading(false);
        }
        return () => {
            setLoading(false);
            setSettings({});
            ac.abort();
        };
    }, []);

    const doUpdateSettings = (_settings: any) => {
        updateNotificationsSettingsForResource(resource, _settings).then((updatedSettings) => {
            setSettings(updatedSettings);
        });
    };

    const actions: ActionType[] = [
        {
            key: "notifyOnMigration",
            label: "migration notification",
        },
        {
            key: "notify30Days",
            label: "30 days before expiration",
        },
        {
            key: "notify60Days",
            label: "60 days before expiration",
        },
        {
            key: "notify90Days",
            label: "90 days before expiration",
        },
        {
            key: "notifyGroups",
            label: "Send notification to user groups.",
            divider: true,
        },
    ];

    const checkedCount = actions.reduce((pre, cur) => (pre += settings[cur.key] ? 1 : 0), 0);

    const isAll = checkedCount === actions.length;
    const isDeterminate = checkedCount > 0 && checkedCount < actions.length;

    const trail = isAll ? "All" : checkedCount;

    const items = actions.map(({ key, label, divider }) => ({
        label: (
            <>
                {divider && <Divider style={{ margin: "10px 0" }} />}
                <Checkbox
                    disabled={disabled}
                    checked={settings[key] as boolean}
                    onChange={(e) => {
                        doUpdateSettings({ [key]: e.target.checked });
                    }}
                >
                    {label}
                </Checkbox>
            </>
        ),
        key,
    }));

    return (
        <Skeleton loading={loading}>
            <ActionDropdown
                trigger={["click"]}
                open={open}
                onOpenChange={setOpen}
                menu={{
                    items,
                }}
            >
                <Space>
                    <Checkbox checked={isAll} indeterminate={isDeterminate}></Checkbox>
                    <div>Notification ({trail})</div>
                </Space>
            </ActionDropdown>
        </Skeleton>
    );
};
