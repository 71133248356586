import React, { useEffect, useState } from "react";
import { Avatar, Form, Input, Modal, ModalProps, Select } from "antd";
import { Vendor, VendorIndustry } from "../../../types/VendorTypes";
import { Industry } from "../../../types/IndustryTypes";
import { getIndustries } from "../../../common/api/IndustryClient";
import { handleError } from "../../../common/ErrorHandling";
import { getVendorIndustry } from "../../../common/api/VendorClient";

interface VendorUpdateModalProps extends Omit<ModalProps, "onOk"> {
    vendor?: Vendor;
    onOk?: (vendor: Vendor, industry?: VendorIndustry) => void;
}
export const VendorUpdateModal: React.FC<VendorUpdateModalProps> = ({
    vendor,
    onOk,
    ...restProps
}) => {
    const [vendorCopy, setVendorCopy] = useState({} as Vendor);
    const [data, setData] = useState<Industry[]>([]);
    const [vendorIndustry, setVendorIndustry] = useState({} as VendorIndustry);

    const sectors = data.filter((item) => !item.pid);
    const industries = data.filter((item) => item.pid === vendorIndustry.sectorId);

    const loadIndustry = async () => {
        try {
            const data = await getIndustries();
            setData(data);
        } catch (error) {
            handleError(error);
        }
    };

    const loadVendorIndustry = async () => {
        if (!vendor) return;
        try {
            const data = await getVendorIndustry(vendor);
            setVendorIndustry({ ...data, vendorName: vendor.name });
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        vendor && setVendorCopy(vendor);
    }, [vendor]);

    useEffect(() => {
        if (restProps.open) {
            loadIndustry();
        }
    }, [restProps.open]);

    useEffect(() => {
        if (vendor?.name) {
            loadVendorIndustry();
        }
    }, [vendor?.name]);

    const modal = (
        <>
            <Modal
                title="Update Vendor"
                zIndex={1050}
                {...restProps}
                onOk={() => {
                    onOk?.(vendorCopy, vendorIndustry);
                }}
            >
                <div style={{ paddingLeft: 55, paddingBottom: 20 }}>
                    <Avatar shape="square" src={vendorCopy.hero || ""} />
                    &nbsp;<span style={{ paddingLeft: 10 }}>{vendorCopy.name}</span>
                </div>
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    size="small"
                >
                    <Form.Item label="Vendor name">
                        <Input
                            value={vendorCopy.name}
                            onChange={(e) => {
                                setVendorCopy((old) => ({
                                    ...old,
                                    name: e.target.value,
                                }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Hero image">
                        <Input
                            value={vendorCopy.hero}
                            placeholder="Image url"
                            onChange={(e) => {
                                setVendorCopy((old) => ({
                                    ...old,
                                    hero: e.target.value,
                                }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Sector">
                        <Select
                            value={vendorIndustry.sectorId}
                            options={sectors.map((item) => ({ value: item.id, label: item.name }))}
                            onChange={(value) => {
                                setVendorIndustry((old) => ({
                                    ...old,
                                    sectorId: value,
                                }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Industry">
                        <Select
                            value={vendorIndustry.industryId}
                            options={industries.map((item) => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            onChange={(value) => {
                                setVendorIndustry((old) => ({
                                    ...old,
                                    industryId: value,
                                }));
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
    return <>{modal}</>;
};
