import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import NumberFormat from "react-number-format";
import { getInsights } from "../../common/api/NegotiationsClient";
import { Vendor } from "../../types/VendorTypes";
import { NegotiationInsigtsType } from "../../types/NegotiationTypes";
import { NegotiationInsightCard, NegotiationInsightCardProps } from "./NegotiationInsightCard";
import { NegotiationCotermModal } from "./NegotiationCotermModal";
import { NegotiationPriceDiffModal } from "./NegotiationPriceDiffModal";
import { NegotiationEndDatesModal } from "./NegotiationEndDatesModal";
import { useUserState } from "../../common/UserContext";
import { isPoc } from "../../types/UserGroup";

interface NegotiationInsightsContainerProps {
    vendors?: Vendor[];
}

export const NegotiationInsightsContainer: React.FC<NegotiationInsightsContainerProps> = ({
    vendors = [],
}) => {
    const { me } = useUserState();
    const [showCoterm, setShowCoterm] = useState(false);
    const [showUnitPrice, setShowUnitPrice] = useState(false);
    const [showEndDates, setShowEndDates] = useState(false);
    const [insights, setInsights] = useState<NegotiationInsightCardProps[]>([]);
    const [insightsDetail, setInsightsDetail] = useState({} as NegotiationInsigtsType);

    useEffect(() => {
        getInsights(vendors.map((v) => v.vendorKey)).then((res) => {
            const discrepancy = res.priceDiff.discrepancy;
            const discrepancyPrefix = discrepancy > 0 ? "↑ " : discrepancy < 0 ? "↓ " : "";
            setInsightsDetail(res);

            setInsights([
                {
                    label: "Consolidation",
                    tips: "Discover overlapping suppliers or multiple order forms for the same supplier to streamline operations and drive efficiency.",
                    data: `-${res?.coterm?.reduce} contracts`,
                    detail: `Contracts: ${res?.coterm?.before} > Contracts: ${res?.coterm?.after}`,
                    onClick: () => setShowCoterm(true),
                },
                {
                    label: "SKU Price Variance",
                    tips: "Identify any increases or decreases in SKU pricing compared to previous deals to ensure cost consistency and optimize savings.",
                    data: (
                        <span>
                            {discrepancyPrefix}
                            <NumberFormat
                                prefix="$"
                                thousandSeparator={true}
                                displayType={"text"}
                                value={Math.abs(discrepancy)}
                            ></NumberFormat>
                        </span>
                    ),
                    detail: `Number of SKUs: ${res?.priceDiff?.skuNumber}   Number of vendors: ${res?.priceDiff?.vendorNumber}`,
                    onClick: () => setShowUnitPrice(true),
                },
                {
                    label: "Strategic Renewal Planning",
                    tips: "Identify supplier quarter and year-end dates for upcoming contract renewals, enabling you to strategically time negotiations for better outcomes aligned with their sales deadlines.",
                    data: `${res?.endDates?.total} Expiring deals`,
                    detail: `Early renewals: ${res?.endDates?.earlyEnd}   Normal renewals:${res?.endDates?.normalEnd}`,
                    onClick: () => setShowEndDates(true),
                },
            ]);
        });
    }, [vendors, vendors.length]);

    if (isPoc(me.roles)) return null;

    return (
        <Row gutter={20} style={{ marginTop: 20 }}>
            {insights.map((v) => (
                <Col span={8} key={v.label}>
                    <NegotiationInsightCard {...v} />
                </Col>
            ))}
            {insightsDetail.coterm?.orderForms && (
                <NegotiationCotermModal
                    visible={showCoterm}
                    orderForms={insightsDetail.coterm.orderForms}
                    onCancel={() => setShowCoterm(false)}
                />
            )}
            {insightsDetail.priceDiff?.changedOrderSKUs && (
                <NegotiationPriceDiffModal
                    visible={showUnitPrice}
                    changedOrderSKUs={insightsDetail.priceDiff.changedOrderSKUs}
                    discrepancy={insights[1]?.data}
                    onCancel={() => setShowUnitPrice(false)}
                />
            )}
            {insightsDetail.endDates?.orderForms && (
                <NegotiationEndDatesModal
                    visible={showEndDates}
                    orderForms={insightsDetail.endDates.orderForms}
                    onCancel={() => setShowEndDates(false)}
                />
            )}
        </Row>
    );
};
