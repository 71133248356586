import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { getVendors } from "../../common/api/VendorClient";
import { Vendor } from "../../types/VendorTypes";
import { getNegotiation, updateNegotiation } from "../../common/api/NegotiationsClient";
import { Negotiation, NegotiationStatus } from "../../types/NegotiationTypes";
import { NegotiationInsightsContainer } from "./NegotiationInsightsContainer";
import { NegotiationDetailBreadcrumb } from "./NegotiationDetailBreadcrumb";
import { NegotiationDetailToolbox } from "./NegotiationDetailToolbox";

interface NegotiationDetailInsightsProps {
    negotiationId: number;
}
export const NegotiationDetailInsights: React.FC<NegotiationDetailInsightsProps> = ({
    negotiationId,
}) => {
    const [negotiation, setNegotiation] = useState({} as Negotiation);

    const [reload, setReload] = useState(0);
    const [selectedVendors, setSelectedVendors] = useState([{ vendorKey: "" } as Vendor]);

    const refreshNegotiation = async () => {
        const res = await getNegotiation(negotiationId);
        setNegotiation(res);
        return res;
    };

    const refreshVendors = async () => {
        const res = await refreshNegotiation();
        const vendors = await getVendors([res.vendorKey]);
        if (vendors.length) {
            setSelectedVendors(vendors);
        }
    };

    useEffect(() => {
        refreshNegotiation();
    }, [reload]);

    useEffect(() => {
        refreshVendors();
    }, [negotiationId]);

    const handleStatusChange = async (status: NegotiationStatus) => {
        const newNegotiation = { ...negotiation, status };
        await updateNegotiation(newNegotiation);
        setReload(reload + 1);
    };

    return (
        <>
            <NegotiationDetailBreadcrumb negotiation={negotiation} />
            <div>
                <Row align="middle">
                    <Col span={20}>
                        <span>Insights - </span>
                        <span>{negotiation.name}</span>
                    </Col>

                    <Col span={4} style={{ textAlign: "right" }}>
                        <NegotiationDetailToolbox
                            negotiation={negotiation}
                            vendor={selectedVendors[0]}
                            onStatusChange={handleStatusChange}
                        />
                    </Col>
                </Row>
                <NegotiationInsightsContainer vendors={selectedVendors} />
            </div>
        </>
    );
};
