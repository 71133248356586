import React from "react";
import { Col, Row, Segmented } from "antd";
import { SupplierFullCompetitor, SupplierWithEmployee } from "../../types/SupplierTypes";
import { BusinessDrivers } from "./components/BusinessDrivers";
import { CompetitorsTable } from "./components/CompetitorsTable";
import { SupplierOverviewsContainer } from "./components/SupplierOverviewsContainer";
import { SupplierProducts } from "./components/SupplierProducts";
import { SupplierProfile } from "./components/SupplierProfile";
import { IncomeStatement, FinancialResponse } from "../../types/FinanceTypes";
import {
    formatNumber,
    getTrendDataColor,
    getTrendDataPrefix,
    roundToTwoDecimals,
} from "../../common/Number";
import { getQuarter } from "../../types/TimeTypes";

export enum FinancialOverviewOption {
    Annually = "Annually",
    Quarterly = "Quarterly",
}
const financialOverviewOptions = Object.keys(FinancialOverviewOption);

interface FormattedFinancialHistory {
    dataLabel: string | number;
    data: string;
    dataColor?: string;
    mainData?: number;
}

type FinancialHistoryMap = {
    [key in FinancialOverviewOption]: {
        revenue?: FormattedFinancialHistory[];
        grossProfit?: FormattedFinancialHistory[];
        netIncome?: FormattedFinancialHistory[];
    };
};

const financialHistoryMap = {
    [FinancialOverviewOption.Annually]: {},
    [FinancialOverviewOption.Quarterly]: {},
} as FinancialHistoryMap;

const formatAnnuallyHistory = (
    allDatesData: IncomeStatement[] = [],
    field: keyof IncomeStatement
) => {
    const dataLength = allDatesData?.length;
    if (!dataLength || dataLength < 2) return;

    const result = [] as FormattedFinancialHistory[];
    const mainData = Number(allDatesData[dataLength - 1][field]);

    for (let i = 1; i < allDatesData.length; i++) {
        const dataLabel = allDatesData[i].fiscalYear;
        const current = Number(allDatesData[i][field]);
        const previous = Number(allDatesData[i - 1][field]);
        const changePercentage = (current - previous) / Math.abs(previous) || 0;

        const formattedPercentage = Math.abs(roundToTwoDecimals(changePercentage * 100));
        const prefix = getTrendDataPrefix(changePercentage);

        result.push({
            dataLabel,
            data: `${prefix}${formattedPercentage}%`,
            dataColor: getTrendDataColor(changePercentage),
            mainData,
        });
    }
    return result;
};

const formatQuarterlyHistory = (
    allDatesData: IncomeStatement[] = [],
    field: keyof IncomeStatement
) => {
    const dataLength = allDatesData?.length;
    if (!dataLength || dataLength < 2) return;

    const result = [] as FormattedFinancialHistory[];
    const mainData = Number(allDatesData[dataLength - 2][field]);

    for (let i = 0; i < allDatesData.length; i++) {
        const isLast = i === allDatesData.length - 1;
        const date = new Date(allDatesData[i].date);
        const label = `${date.getFullYear()}Q${getQuarter(date)}`;

        const data = `${formatNumber(Number(allDatesData[i][field]))}`;
        const dataLabel = isLast ? "Annual" : label;
        const change = isLast ? 1 : 0;

        result.push({
            dataLabel,
            data,
            dataColor: getTrendDataColor(change),
            mainData,
        });
    }
    return result;
};

interface SupplierDetailLeftPanelProps {
    profile: SupplierWithEmployee;
    employeeCount?: number;
    employeeChange?: number;
    financials?: FinancialResponse;
    competitors?: SupplierFullCompetitor[];
    showOverviewToggle?: boolean;
    overviewOption: FinancialOverviewOption;
    onOverviewChange?: (overviewOption: FinancialOverviewOption) => void;
}

const fields = ["revenue", "grossProfit", "netIncome"] as const;

export const SupplierDetailLeftPanel: React.FC<SupplierDetailLeftPanelProps> = ({
    profile,
    employeeCount,
    employeeChange,
    financials,
    competitors,
    showOverviewToggle = true,
    overviewOption,
    onOverviewChange,
}) => {
    fields.forEach((field) => {
        financialHistoryMap.Annually[field] = formatAnnuallyHistory(financials?.annually, field);
        financialHistoryMap.Quarterly[field] = formatQuarterlyHistory(financials?.quarterly, field);
    });

    const financialsHistory = financialHistoryMap[overviewOption];

    const { revenue, grossProfit, netIncome } = financialsHistory;

    const overviews = [
        {
            title: "Revenue",
            tip: "The total income generated from goods sold or services provided during a certain time frame.",
            data: revenue?.[0]?.mainData || 0,
            dataColor: revenue?.[revenue?.length - 1]?.dataColor,
            history: revenue,
        },
        {
            title: "Gross profit",
            tip: "The profit made after subtracting all (variable) costs that are related to manufacturing of its products or services. The gross profit can be calculated by deducting the cost of goods sold (COGS) from total sales.",
            data: grossProfit?.[0]?.mainData || 0,
            dataColor: grossProfit?.[grossProfit?.length - 1]?.dataColor,
            history: grossProfit,
        },
        {
            title: "Net income",
            tip: "The total profit of the company after all expenses, including taxes and other deductions, have been taken into account.",
            data: netIncome?.[0]?.mainData || 0,
            dataColor: netIncome?.[netIncome?.length - 1]?.dataColor,
            history: netIncome,
        },
    ];

    return (
        <div
            style={{
                fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            }}
        >
            <SupplierProfile
                {...profile}
                currentEmployee={employeeCount}
                employeeDifference={employeeChange}
            />
            {profile.products?.length ? (
                <>
                    <div style={{ marginTop: 20, fontWeight: 500 }}>Key offerings</div>
                    <SupplierProducts products={profile.products} />
                </>
            ) : null}

            <Row style={{ marginTop: 20, marginBottom: 8 }}>
                <Col flex={1} style={{ fontWeight: 500 }}>
                    Overview
                </Col>
                {showOverviewToggle && (
                    <Col>
                        <Segmented
                            options={financialOverviewOptions}
                            value={overviewOption}
                            onChange={(value) =>
                                onOverviewChange?.(value as FinancialOverviewOption)
                            }
                        />
                    </Col>
                )}
            </Row>
            <SupplierOverviewsContainer overviews={overviews} />
            {competitors?.length ? (
                <>
                    <div style={{ marginTop: 20, marginBottom: 8, fontWeight: 500 }}>
                        Top 3 competitors - Ranked by performance
                    </div>
                    <CompetitorsTable data={competitors} />
                </>
            ) : null}
            {profile.businessDrivers?.length ? (
                <>
                    <div style={{ marginTop: 20, fontWeight: 500 }}>Key business drivers</div>
                    <BusinessDrivers drivers={profile.businessDrivers} />
                </>
            ) : null}
        </div>
    );
};
