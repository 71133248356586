import { FC } from "react";
import "./App.less";

import { BrowserRouter as Router } from "react-router-dom";
import { UserStateProvider } from "./common/UserContext";
import { AppRoutes } from "./AppRoutes";

/**
 *
 * TODO: OPTIMIZE ROUTE RENDERING
 */
const App: FC = () => (
    <Router>
        <UserStateProvider>
            <AppRoutes />
        </UserStateProvider>
    </Router>
);

export default App;
