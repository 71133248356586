import { Form, Modal, ModalProps, Select } from "antd";
import { useEffect, useState } from "react";
import { User, UserRole } from "../../../types/UserGroup";

export interface UserUpdateModalProps extends Omit<ModalProps, "onOk"> {
    user?: User;
    onOk?: (user: User) => void;
}

export const UserUpdateModal: React.FC<UserUpdateModalProps> = ({ user, onOk, ...restProps }) => {
    const [userCopy, setUserCopy] = useState({} as User);

    useEffect(() => {
        user && setUserCopy(user);
    }, [user]);

    const modal = (
        <Modal
            title={"Update User"}
            {...restProps}
            onOk={async () => {
                onOk?.(userCopy);
            }}
        >
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} layout="horizontal" size="small">
                <Form.Item label="Roles">
                    <Select
                        value={userCopy?.roles}
                        mode="multiple"
                        onChange={(values) => {
                            setUserCopy((old) => ({
                                ...old,
                                roles: values,
                            }));
                        }}
                    >
                        {Object.values(UserRole).map((role) => (
                            <Select.Option value={role}>{role}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
    return modal;
};
