import React from "react";
import { Row, Space, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

export interface NegotiationInsightCardProps {
    label: string;
    tips: string;
    data: React.ReactNode;
    detail: React.ReactNode;
    onClick?: () => void;
}

export const NegotiationInsightCard: React.FC<NegotiationInsightCardProps> = ({
    label,
    tips,
    data,
    detail,
    onClick,
}) => (
    <div
        style={{
            padding: "12px 16px",
            borderRadius: "8px",
            background: "#FAFAFA",
            cursor: "pointer",
            borderBottom: "1px solid #D9D9D9",
            color: "#000000",
        }}
        onClick={onClick}
    >
        <Row>
            <Space>
                <span style={{ fontSize: 16 }}>{label}</span>
                <Tooltip title={tips}>
                    <QuestionCircleFilled style={{ color: "#1890FF" }} />
                </Tooltip>
            </Space>
        </Row>
        <Row
            justify="center"
            style={{
                color: "#FF8400",
                fontSize: 28,
                fontWeight: 500,
            }}
        >
            {data}
        </Row>
        <Row justify="center" style={{ whiteSpace: "pre" }}>
            {detail}
        </Row>
    </div>
);
