import React from "react";
import { Typography, Tooltip, Row, Col } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { NegotiationLeverDetail } from "../../types/NegotiationTypes";
import { NegotiationLeverOutcomeTip } from "./NegotiationLeverOutcomeTip";
import { ReactComponent as PlusIcon } from "../../assets/icons/svg/plus.svg";
import "./NegotiationLeverItem.less";

export interface NegotiationLeverItemProps {
    negotiationLever: NegotiationLeverDetail;
    onClick?: (lever: NegotiationLeverDetail) => void;
}

const PREFIX = "NegotiationLeverItem";

export const NegotiationLeverItem: React.FC<NegotiationLeverItemProps> = ({
    negotiationLever,
    onClick,
}) => {
    return (
        <div
            data-testid="test-lever"
            className={PREFIX}
            onClick={() => onClick && onClick(negotiationLever)}
        >
            <Row gutter={8} wrap={false}>
                <Col>
                    <PlusIcon className={`${PREFIX}-plus`} />
                </Col>
                <Col flex={1}>
                    <Typography.Text ellipsis className={`${PREFIX}-name`}>
                        {negotiationLever.lever.name}
                    </Typography.Text>
                </Col>
                <Col>
                    <Tooltip
                        title={<NegotiationLeverOutcomeTip negotiationLever={negotiationLever} />}
                    >
                        <QuestionCircleFilled className={`${PREFIX}-question`} />
                    </Tooltip>
                </Col>
            </Row>
        </div>
    );
};
