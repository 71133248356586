import { safeGET, safePOST, safePUT } from "./SafeClient";
import { Industry } from "../../types/IndustryTypes";

export async function getIndustries(abort?: any): Promise<Industry[]> {
    return safeGET(`/api/industry`, abort);
}

export async function createIndustry(
    industry: Omit<Industry, "id">,
    abort?: any
): Promise<Industry> {
    return safePOST(`/api/industry`, industry, abort);
}

export async function updateIndustry(industry: Industry, abort?: any): Promise<Industry> {
    return safePUT(`/api/industry/${industry.id}`, industry, abort);
}
