import React from "react";
import { Dropdown, DropdownProps, Space } from "antd";
import { ReactComponent as Arrow } from "../../assets/icons/svg/ArrowDown.svg";
import "./ActionDropdown.less";

interface ActionDropdownProps extends DropdownProps {}

const PREFIX = "ActionDropdown";

export const ActionDropdown: React.FC<ActionDropdownProps> = ({ children, ...restProps }) => {
    return (
        <div className={PREFIX}>
            <Dropdown {...restProps}>
                <Space className={`${PREFIX}-trigger`}>
                    {children}
                    <Arrow className={`${PREFIX}-trigger-arrow`} />
                </Space>
            </Dropdown>
        </div>
    );
};
