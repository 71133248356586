import { Row, Col, Table, Space, Button, Card, Tooltip, Tabs, Segmented } from "antd";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import csvDownload from "json-to-csv-export";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getVendors } from "../../common/api/VendorClient";
import { handleError } from "../../common/ErrorHandling";
import {
    Filter,
    FilterGroup,
    FilterGroupType,
    FilterOperand,
    Pagination,
} from "../../types/DataFetchingTypes";
import MainLayout from "../navigation/MainLayout";
import { Vendor } from "../../types/VendorTypes";
import { getDayDiff, getFourQuarters, TimeQuarter } from "../../types/TimeTypes";
import {
    createNegotiation,
    listNegotiations,
    updateNegotiation,
} from "../../common/api/NegotiationsClient";
import { getUsers } from "../../common/api/UserClient";
import { isPoc, User } from "../../types/UserGroup";
import {
    Negotiation,
    NegotiationStatus,
    NegotiationStatusCategory,
} from "../../types/NegotiationTypes";
import { SegmentedValue } from "antd/lib/segmented";
import { OrderFormNewModal } from "../orderform/OrderFormNewModal";
import { formatDateString } from "../date/DateOp";
import { alertMessage } from "../../common/AlertMessage";
import { EditableCell } from "../../common/components/EditableCell";
import { NegotiationInsights } from "./NegotiationInsights";
import { NegotiationStatusTag, negotiationStatusToString } from "./NegotiationStatusTag";
import { NegotiationRemoveModal } from "./NegotiationRemoveModal";
import { SupplierCell } from "./components/SupplierCell";
import { useUserState } from "../../common/UserContext";

const existingStatus = [NegotiationStatus.NOT_STARTED, NegotiationStatus.IN_PROGRESS];
const pastStatus = [NegotiationStatus.COMPLETE, NegotiationStatus.CANCELLED];
const existingFilters = existingStatus.map((v) => {
    return {
        attribute: "status",
        operand: FilterOperand.EQUALS,
        value: v,
    } as Filter;
});

const pastFilters = pastStatus.map((v) => {
    return {
        attribute: "status",
        operand: FilterOperand.EQUALS,
        value: v,
    } as Filter;
});
const statusSearch = {
    groupId: "status",
    groupType: FilterGroupType.OR,
    filter: existingFilters,
} as FilterGroup;

export const NegotiationsView: React.FC = () => {
    const navigate = useNavigate();
    const { me } = useUserState();
    const quarters = getFourQuarters(new Date());
    const options = ["All", ...quarters.map((q) => `${q.year} Q${q.quarter}`)];

    const [data, setData] = useState<Negotiation[]>([]);
    const [pagination, setPagination] = useState({
        pages: 0,
        current: 1,
        total: 0,
        pageSize: 10,
    } as Pagination);
    const [statusFilter, setStatusFilter] = useState([statusSearch]);
    const [selectedQuarter, setSelectedQuarter] = useState<TimeQuarter>();
    const [vendorsMap, setVendorsMap] = useState({} as Map<string, Vendor>);
    const [ownersMap, setOwnersMap] = useState({} as Map<number, User>);
    const [loading, setLoading]: any[] = useState(true);
    const [exportLoading, setExportLoading]: any[] = useState(false);
    const [reload, setReload]: any[] = useState(0);
    const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]);
    const [showCreationForm, setShowCreationForm] = useState(false);
    const [negotiationToRemove, setNegotiationToRemove] = useState({} as Negotiation);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [currentEditRowId, setCurrentEditRowId] = useState<number>();

    const defaultFormatter = (value: any) => value;

    const handleRemoveNegotiation = (negotiation: Negotiation) => {
        setNegotiationToRemove(negotiation);
        setShowRemoveModal(true);
    };

    const onNegotiationRemoved = () => {
        setShowRemoveModal(false);
        setReload(reload + 1);
    };

    const resetEditRowId = () => {
        setCurrentEditRowId(undefined);
    };

    const editCategory = async (negotiation: Negotiation) => {
        await updateNegotiation(negotiation);
        setReload(reload + 1);
        resetEditRowId();
    };

    const editSupplier = async (negotiation: Negotiation) => {
        await updateNegotiation(negotiation);
        setReload(reload + 1);
    };

    const columns = [
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            width: 200,
            render: (value: string, row: Negotiation) => (
                <EditableCell
                    content={value}
                    onClick={() => setCurrentEditRowId(row.id)}
                    showEdit={currentEditRowId === row.id}
                    onCancel={resetEditRowId}
                    onOk={(category) => {
                        editCategory({
                            ...row,
                            category,
                        });
                    }}
                />
            ),
            formatter: defaultFormatter,
        },
        {
            title: "Supplier name",
            dataIndex: "vendorKey",
            key: "vendorKey",
            render: (value: string, row: Negotiation) => {
                const vendor = vendorsMap.get(value);
                return (
                    <SupplierCell
                        name={vendor?.name}
                        vendorKey={value}
                        onCreate={(vendor) => {
                            editSupplier({
                                ...row,
                                vendorKey: vendor.vendorKey,
                            });
                        }}
                    />
                );
            },
            formatter: (value: string, allVendorsMap: Map<string, Vendor>) => {
                const vendor = allVendorsMap.get?.(value);
                return vendor?.name;
            },
        },
        {
            title: "Order form name",
            dataIndex: "name",
            key: "name",
            render: (value: string, row: Negotiation) => {
                return <Link to={"/app/negotiations/" + row.id + ""}>{value}</Link>;
            },
            formatter: defaultFormatter,
        },
        {
            title: "Days to expire",
            dataIndex: "expirationDate",
            key: "expirationDate",
            render: (value: string) => {
                return getDayDiff(new Date(), new Date(value));
            },
            formatter: (value: string) => {
                return getDayDiff(new Date(), new Date(value));
            },
        },
        {
            title: "Value",
            dataIndex: "value",
            key: "value",
            render: (value: number) => {
                return (
                    <NumberFormat
                        value={value}
                        thousandSeparator={true}
                        displayType={"text"}
                        prefix={"$"}
                    />
                );
            },
            formatter: (value: number) => `$${value}`,
        },
        {
            title: "Owner",
            dataIndex: "ownerId",
            key: "ownerId",
            render: (value: number) => {
                const owner = ownersMap.get(value);
                return owner?.name;
            },
            formatter: (value: number, _: unknown, allOwnersMap: Map<number, User>) => {
                const owner = allOwnersMap.get?.(value);
                return owner?.name;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: NegotiationStatus) => {
                return <NegotiationStatusTag status={value} />;
            },
            formatter: (value: NegotiationStatus) => {
                return negotiationStatusToString(value);
            },
        },
        {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            render: (_: unknown, row: Negotiation) => {
                return (
                    <Space>
                        <Link to={"/app/negotiations/" + row.id + ""}>Negotiate</Link>
                        <Button
                            danger
                            type="link"
                            icon={<CloseOutlined />}
                            onClick={() => handleRemoveNegotiation(row)}
                        ></Button>
                    </Space>
                );
            },
        },
    ];

    const handleVendorChange = (vendors: Vendor[]) => {
        setSelectedVendors(vendors);

        setPagination({
            ...pagination,
            current: 1,
        });
        setReload(reload + 1);
    };

    const handleQuarterChange = (q: SegmentedValue) => {
        const idx = options.indexOf(q as string);
        if (idx > 0) {
            const quarter = quarters[idx - 1];
            setSelectedQuarter(quarter);
        } else {
            setSelectedQuarter(undefined);
        }
        setReload(reload + 1);
    };

    const handleTabChange = (tabKey: string) => {
        if (tabKey === NegotiationStatusCategory.EXISTING) {
            statusSearch.filter = existingFilters;
        } else if (tabKey === NegotiationStatusCategory.PAST) {
            statusSearch.filter = pastFilters;
        }

        setStatusFilter([statusSearch]);
        setPagination({
            ...pagination,
            current: 1,
        });
        setReload(reload + 1);
    };

    const handleTableChange = (pagination: any) => {
        setPagination(pagination);

        setReload(reload + 1);
    };

    const addNegotiation = async (form: Negotiation) => {
        const res = await createNegotiation(form);
        setShowCreationForm(false);
        alertMessage.success("Your negotiation has been added.");
        navigate(`/app/negotiations/${res.id}`);
    };

    const exportNegotiations = async () => {
        setExportLoading(true);
        const colsWithFormatter = columns.filter((col) => col.formatter);
        const headers = colsWithFormatter.map((col) => col.title);

        try {
            const pager = await listNegotiations(
                {
                    ...pagination,
                    current: 1,
                    pageSize: pagination.total,
                },
                [],
                statusFilter
            );

            if (pager.data.length > 0) {
                const [vendors, owners] = await Promise.all([
                    getVendors(pager.data.map((v) => v.vendorKey)),

                    getUsers(Array.from(new Set(pager.data.map((v) => v.ownerId)))),
                ]);
                const allVendorsMap = new Map(vendors.map((v) => [v.vendorKey, v]));
                const allOwnersMap = new Map(owners.map((v) => [v.id, v]));

                const jsonData = pager.data.map((row) =>
                    colsWithFormatter.reduce((pre, cur) => {
                        return {
                            ...pre,
                            [cur.dataIndex]: cur.formatter!(
                                row[cur.dataIndex],
                                allVendorsMap,
                                allOwnersMap
                            ),
                        };
                    }, {})
                );

                const dataToConvert = {
                    data: jsonData,
                    filename: `negotiation_${formatDateString(new Date())}`,
                    delimiter: ",",
                    headers,
                };
                csvDownload(dataToConvert);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setExportLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        listNegotiations(
            pagination,
            [],
            statusFilter,
            selectedQuarter,
            selectedVendors.map((v) => v.vendorKey)
        )
            .then(async (pager) => {
                if (pager.data.length > 0) {
                    const [vendors, owners] = await Promise.all([
                        getVendors(pager.data.map((v) => v.vendorKey)),

                        getUsers(Array.from(new Set(pager.data.map((v) => v.ownerId)))),
                    ]);
                    const vendorMap = new Map(vendors.map((v) => [v.vendorKey, v]));
                    const ownerMap = new Map(owners.map((v) => [v.id, v]));
                    setVendorsMap(vendorMap);
                    setOwnersMap(ownerMap);
                }

                setData(pager.data);
                setPagination(pager.pagination);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [reload]);

    const negotiationTable = (
        <Table
            dataSource={data}
            pagination={pagination}
            loading={loading}
            rowKey="id"
            onChange={handleTableChange}
            columns={columns}
        ></Table>
    );

    const negotiationHeader = (
        <div style={{ marginTop: 20 }}>
            <Row align="middle">
                <Col flex={1}>
                    <Space>
                        Negotiation - Simulation
                        <Tooltip
                            title={
                                "Simulate contract negotiations with suppliers to determine the most effective levers for achieving the best outcomes."
                            }
                        >
                            <ExclamationCircleOutlined style={{ color: "#1890FF" }} />
                        </Tooltip>
                    </Space>
                </Col>
                {isPoc(me.roles) ? null : (
                    <Button type="link" onClick={exportNegotiations} loading={exportLoading}>
                        Export all
                    </Button>
                )}
            </Row>
            <Tabs
                tabBarExtraContent={
                    <>
                        <Button type="primary" onClick={() => setShowCreationForm(true)}>
                            Add negotiation
                        </Button>
                        <Segmented
                            options={options}
                            style={{ marginLeft: 12 }}
                            onChange={handleQuarterChange}
                        />
                    </>
                }
                items={[
                    { label: "Existing", key: NegotiationStatusCategory.EXISTING },
                    { label: "Past", key: NegotiationStatusCategory.PAST },
                ]}
                onChange={handleTabChange}
            />
            <OrderFormNewModal
                title="Add negotiation"
                showCategory
                showForm={showCreationForm}
                onClose={() => setShowCreationForm(false)}
                onOk={(form) => addNegotiation(form as unknown as Negotiation)}
            />
        </div>
    );

    const panel = (
        <div>
            <Card>
                <NegotiationInsights vendors={selectedVendors} onSelect={handleVendorChange} />
                {negotiationHeader}
                <Row>
                    <Col span={24}>{negotiationTable}</Col>
                </Row>
            </Card>
            <NegotiationRemoveModal
                open={showRemoveModal}
                value={negotiationToRemove}
                onCancel={() => setShowRemoveModal(false)}
                onRemove={onNegotiationRemoved}
            />
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"Negotiations"} collapsed={true} />;
};
