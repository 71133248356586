import React, { useEffect, useState } from "react";
import { Button, Col, Row, Skeleton, Space } from "antd";
import { ActionDropdown } from "./ActionDropdown";
import "./NotificationSetting.less";
import { handleError } from "../ErrorHandling";
import { addGroupToOrder, getOrderGroups, removeGroupFromOrder } from "../api/OrderFormClient";
import { getGroups } from "../api/UserClient";
import { UserGroupSelectionModal } from "../../components/user/UserGroupSelectionModal";
import { Group, UGType } from "../../types/UserGroup";
import { ReactComponent as PlusIcon } from "../../assets/icons/svg/plus.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/svg/trash.svg";
import { CompanyImage } from "./CompanyImage";

export interface GroupSettingProps {
    orderFormId: number;
    disabled?: boolean;
}

export const GroupSetting: React.FC<GroupSettingProps> = ({ orderFormId, disabled }) => {
    const [open, setOpen] = useState(false);

    const [useRecipients, setUseRecipients] = useState([] as number[]);
    const [members, setMembers] = useState([] as Group[]);
    const [showUGSelectionModal, setShowUGSelectionModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!orderFormId) {
            return;
        }
        setLoading(true);

        const fn = async () => {
            const groupIds = await getOrderGroups(orderFormId);
            setUseRecipients(groupIds);
        };

        try {
            fn();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const groups = await getGroups(useRecipients);
                setMembers(groups);
            } catch (error: any) {
                handleError(error);
            }
        })().finally(() => {
            setLoading(false);
        });
    }, [useRecipients]);

    const removeRecipient = (recipientId: number) => {
        setLoading(true);
        const fn = async () => {
            const removed = await removeGroupFromOrder(orderFormId, [recipientId]);
            const filtered = useRecipients.filter((r) => removed.values.indexOf(r) === -1);
            setUseRecipients(filtered);
        };
        try {
            fn();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
            setShowUGSelectionModal(false);
        }
    };

    const selectRecipientModal = showUGSelectionModal ? (
        <UserGroupSelectionModal
            blackList={useRecipients.map((v) => {
                return { id: v, type: UGType.GROUP };
            })}
            onSelect={(selection) => {
                const groupId = selection.id;
                const fn = async () => {
                    const added = await addGroupToOrder(orderFormId, [groupId]);
                    setUseRecipients(useRecipients.concat(added.values));
                };
                setLoading(true);
                try {
                    fn();
                } catch (error: any) {
                    handleError(error);
                } finally {
                    setLoading(false);
                    setShowUGSelectionModal(false);
                }
            }}
            onCancel={() => {
                setShowUGSelectionModal(false);
            }}
        />
    ) : (
        <></>
    );

    const items = [
        {
            label: (
                <Row justify="center">
                    <Button
                        type="link"
                        icon={
                            <PlusIcon
                                width={10}
                                height={10}
                                fill="#1890FF"
                                style={{ marginRight: 4 }}
                            />
                        }
                        disabled={disabled}
                        onClick={() => {
                            setShowUGSelectionModal(true);
                        }}
                    >
                        Add new group
                    </Button>
                </Row>
            ),
            key: "add",
        },
        ...members.map(({ id, name }) => ({
            label: (
                <Row align="middle" style={{ minWidth: 260 }}>
                    <Col flex={1}>
                        <Space>
                            <CompanyImage name={name} size="mini"></CompanyImage>
                            <span style={{ color: "#404040" }}>{name}</span>
                        </Space>
                    </Col>
                    <Col>
                        <Button
                            type="link"
                            icon={
                                <TrashIcon
                                    width={12}
                                    height={12}
                                    fill="#FD3559"
                                    style={{ verticalAlign: "middle" }}
                                />
                            }
                            disabled={disabled}
                            onClick={() => removeRecipient(id)}
                        ></Button>
                    </Col>
                </Row>
            ),
            key: id,
        })),
    ];
    return (
        <>
            {selectRecipientModal}
            <Skeleton loading={loading}>
                <ActionDropdown
                    trigger={["click"]}
                    open={open}
                    onOpenChange={setOpen}
                    menu={{
                        items,
                    }}
                >
                    <div>Access groups ({members.length})</div>
                </ActionDropdown>
            </Skeleton>
        </>
    );
};
