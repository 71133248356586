import React, { CSSProperties, useState } from "react";
import { Image } from "antd";
import { getInitials } from "./NameAvatar";

type SizeType = "large" | "middle" | "small" | "mini";

type SizeStyleType = {
    [key in SizeType]: CSSProperties;
};

const SizeStyle: SizeStyleType = {
    large: {
        width: 80,
        height: 80,
        padding: 12,
        fontSize: 24,
    },
    middle: {
        width: 60,
        height: 60,
        padding: 12,
        fontSize: 24,
    },
    small: {
        width: 48,
        height: 48,
        padding: 8,
        fontSize: 20,
    },
    mini: {
        width: 36,
        height: 36,
        padding: 4,
        fontSize: 16,
    },
};

interface CompanyImageProps {
    name?: string;
    logo?: string;
    size?: SizeType;
    className?: string;
}

export const CompanyImage: React.FC<CompanyImageProps> = ({
    logo = "",
    name = "",
    size = "middle",
    className,
}) => {
    const [imgError, setImgError] = useState(false);

    const handleImgError = () => {
        setImgError(true);
    };

    const sizeStyle = SizeStyle[size];

    return (
        <div
            className={className}
            style={{
                ...sizeStyle,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "inherit",
                borderRadius: 8,
                background: "#F5F5F5",
                borderBottom: "1px solid #D9D9D9",
            }}
            data-testid="company-image-box"
        >
            {logo && !imgError ? (
                <Image
                    src={logo}
                    preview={false}
                    data-testid="company-image"
                    onError={handleImgError}
                ></Image>
            ) : (
                <span
                    style={{
                        color: "#666666",
                        fontWeight: 700,
                        fontSize: sizeStyle.fontSize,
                    }}
                >
                    {getInitials(name)}
                </span>
            )}
        </div>
    );
};
