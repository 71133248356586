import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { getUsers } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";
import { User } from "../../types/UserGroup";
import { UserImage } from "./UserImage";

interface UserCardProps {
    userId?: number;
    size?: number;
    onClick?: () => void;
}

export const UserCard: React.FC<UserCardProps> = ({ userId, onClick, size }) => {
    const [user, setUser] = useState({} as User);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fn = async () => {
            try {
                if (!userId) {
                    return;
                }
                const res = await getUsers([userId]);
                setUser(res[0]);
            } catch (error: any) {
                handleError(error);
            } finally {
                setLoading(false);
            }
        };
        fn();
    }, [userId]);

    return (
        <Skeleton loading={loading}>
            <UserImage user={user} onClick={onClick} size={size} />
        </Skeleton>
    );
};
