import { Col, Row, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GroupSetting } from "../../../common/components/GroupSetting";
import { NotificationSetting } from "../../../common/components/NotificationSetting";
import { OrderForm } from "../../../types/OrderFormTypes";
import { ResourceType } from "../../../types/ResourceType";
import { OrderFormTable } from "./OrderFormTable";
import { Vendor } from "../../../types/VendorTypes";
import { SvgIcon } from "../../../common/components/SvgIcon";
import { getVendors } from "../../../common/api/VendorClient";

interface OrderFormTablePanelProps {
    orderFormId: number;
    orderForm: OrderForm;
    disabled?: boolean;
    onChange?: (orderForm: OrderForm) => void;
}

export const OrderFormTablePanel: React.FC<OrderFormTablePanelProps> = ({
    orderFormId,
    orderForm,
    onChange,
    disabled,
}) => {
    const [vendor, setVendor] = useState<Vendor>();
    const loadVendor = async () => {
        if (orderForm.vendorKey) {
            const res = await getVendors([orderForm.vendorKey]);
            setVendor(res[0]);
        }
    };
    useEffect(() => {
        loadVendor();
    }, [orderForm]);

    return (
        <div
            style={{
                padding: 12,
                borderBottom: "1px solid ##d9d9d9",
                borderRadius: 8,
                background: "#FAFAFA",
            }}
        >
            <Row>
                <Col flex={1}>
                    <div style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}>
                        {orderForm.name}
                    </div>
                    <div style={{ color: "#404040" }}>{vendor?.description}</div>
                </Col>
                <Col>
                    <Space>
                        <NotificationSetting
                            resourceId={orderFormId}
                            resourceType={ResourceType.OrderForm}
                            disabled={disabled}
                        />
                        <GroupSetting orderFormId={orderFormId} disabled={disabled} />
                        <Link to="/app/supplier">
                            <Tooltip title="Go to buyer brief">
                                <SvgIcon name="briefs" />
                            </Tooltip>
                        </Link>
                        <Link to="/app/negotiations">
                            <Tooltip title="Go to negotiation simulation">
                                <SvgIcon name="group" />
                            </Tooltip>
                        </Link>
                    </Space>
                </Col>
            </Row>
            <OrderFormTable orderForm={orderForm} onChange={onChange} disabled={disabled} />
        </div>
    );
};
