import React, { useEffect, useRef, useState } from "react";
import { Progress, ProgressProps } from "antd";

interface ProgressWithInnerLabelProps extends ProgressProps {}

export const ProgressWithInnerLabel: React.FC<ProgressWithInnerLabelProps> = (props) => {
    const {
        percent = 0,
        strokeWidth = 24,
        showInfo: showAntInfo = false,
        format = (per) => `${per}%`,
    } = props;

    const status = percent > 75 ? "success" : percent > 25 ? "exception" : undefined;

    const labelRef = useRef<HTMLSpanElement>(null);
    const progressRef = useRef<HTMLDivElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [barWidth, setBarWidth] = useState(0);

    const minLeft = labelWidth + 10;
    const labelColor = barWidth > minLeft ? "#FFFFFF" : "#000000";

    useEffect(() => {
        if (labelRef.current) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
        if (progressRef.current) {
            setBarWidth((progressRef.current.offsetWidth / 100) * percent);
        }
    }, [percent, format]);

    return (
        <div ref={progressRef} style={{ position: "relative" }}>
            <Progress
                status={status}
                showInfo={showAntInfo}
                strokeWidth={strokeWidth}
                {...props}
            ></Progress>
            {!showAntInfo && (
                <span
                    ref={labelRef}
                    style={{
                        position: "absolute",
                        left: `max(${minLeft}px,calc(${percent}% - 10px))`,
                        color: labelColor,
                        top: "50%",
                        transform: `translate(-100%,-50%)`,
                        fontWeight: 500,
                    }}
                >
                    {format?.(percent)}
                </span>
            )}
        </div>
    );
};
