import { Attachment } from "../../types/AttachmentTypes";
import { Resource } from "../../types/ResourceType";
import { safeFormPOST, safeGET } from "./SafeClient";

export function addAttachemt(resource: Resource, file: File, abort?: any): Promise<Attachment> {
    const formData = new FormData();
    formData.append("file", file);

    return safeFormPOST(
        `/api/attachment/${resource.resourceType}/${resource.resourceId}`,
        formData,
        abort
    );
}

export function getAttachemts(resource: Resource, abort?: any): Promise<Attachment[]> {
    return safeGET(`/api/attachment/${resource.resourceType}/${resource.resourceId}`, abort);
}

export function getAttachemtUrl(
    resource: Resource,
    attachmentId: number,
    abort?: any
): Promise<{ url: string }> {
    return safeGET(
        `/api/attachment/${resource.resourceType}/${resource.resourceId}/${attachmentId}/url`,
        abort
    );
}
