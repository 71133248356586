import React, { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Button, Col, Input, message, Row, Space, Spin, Upload, Image, UploadProps } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { SvgIcon } from "./SvgIcon";
import { sendMessage } from "../api/ChatClient";
import { ChatResponse } from "../../types/ChatTypes";
import { handleError } from "../ErrorHandling";
import { SavingsChatMessage } from "./SavingsChatMessage";
import { getBase64 } from "../Base64";
import styles from "./IntelligenceModalFooter.module.less";

interface IntelligenceModalFooterProps {
    placeholder?: string;
}

export const IntelligenceModalFooter: React.FC<IntelligenceModalFooterProps> = ({
    placeholder = "Type or ask me anything",
}) => {
    const [loading, setLoading] = useState(false);
    const [inputText, setInputText] = useState("");
    const [base64Image, setBase64Image] = useState<string>();
    const [chatResponse, setChatResponse] = useState<ChatResponse>();
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition();

    const hanldeListen = () => {
        if (!browserSupportsSpeechRecognition) {
            return message.error("Browser doesn't support speech recognition");
        }
        if (!isMicrophoneAvailable) {
            return message.error("Microphone is not available");
        }
        if (listening) {
            SpeechRecognition.stopListening();
            return;
        }

        if (transcript) resetTranscript();
        if (inputText) setInputText("");
        SpeechRecognition.startListening({ continuous: true, language: "en-US" });
    };

    const handleEnterKeyDown = async () => {
        try {
            setLoading(true);
            if (!inputText) {
                return message.error("Please enter a message");
            }
            const res = await sendMessage({ message: inputText, imageUrl: base64Image });
            setChatResponse(res);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange: UploadProps["onChange"] = async ({ fileList }) => {
        if (!fileList.length) return;
        const file = fileList[0];

        const isLt5M = Number(file.size) / 1024 / 1024 < 5;
        if (!isLt5M) {
            return message.error("Image must smaller than 5MB!");
        }

        if (!file.originFileObj) return;
        const base64 = await getBase64(file.originFileObj);
        setBase64Image(base64);
    };

    useEffect(() => {
        setInputText(transcript);
    }, [transcript]);

    useEffect(() => {
        return () => {
            SpeechRecognition.abortListening();
        };
    }, []);

    const answerView = (
        <div className={styles.answer}>
            <div>{chatResponse?.message}</div>
            {chatResponse?.savings && <SavingsChatMessage {...chatResponse.savings} />}
        </div>
    );

    const attachmentView = base64Image ? (
        <Row className={styles.attachment}>
            <div className={styles.attachmentItem}>
                <Image src={base64Image} height={80} className={styles.attachmentImage}></Image>
                <CloseCircleFilled
                    onClick={() => setBase64Image(undefined)}
                    className={styles.attachmentClose}
                />
            </div>
        </Row>
    ) : null;

    return (
        <>
            {chatResponse && answerView}
            <Spin spinning={loading}>
                <Row onKeyDown={(e) => e.key === "Enter" && handleEnterKeyDown()}>
                    <Col flex={1}>
                        <Input
                            size="large"
                            bordered={false}
                            placeholder={placeholder}
                            style={{
                                color: "#C2C9D6",
                            }}
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                        ></Input>
                    </Col>
                    <Col>
                        <Space>
                            <Upload
                                showUploadList={false}
                                beforeUpload={() => false}
                                onChange={handleFileChange}
                                accept="image/*"
                                maxCount={1}
                            >
                                <Button
                                    type="link"
                                    icon={<SvgIcon name="plus" color="#5AD81D" size={18} />}
                                ></Button>
                            </Upload>
                            <Button
                                className={`${styles.mic}${
                                    listening ? ` ${styles.listening}` : ""
                                }`}
                                type="link"
                                icon={<SvgIcon name="mic" color="#5AD81D" size={18} />}
                                onClick={() => hanldeListen()}
                            ></Button>
                        </Space>
                    </Col>
                </Row>
                {attachmentView}
            </Spin>
        </>
    );
};
