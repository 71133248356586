import React, { useEffect, useState } from "react";
import { Button, Card, notification, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import MainLayout from "../navigation/MainLayout";
import { TopSuppliersPanel } from "./TopSuppliersPanel";
import { CostChartPanel } from "./CostChartPanel";
import { VendorHeatmapPanel } from "./VendorHeatmapPanel";
import { getOrderForm } from "../../common/api/OrderFormClient";
import { getExpiringOrderNotifications } from "../../common/api/NotificationsClient";
import { OrderForm } from "../../types/OrderFormTypes";
import { UserGuideModal } from "../../common/components/UserGuideModal";
import { useUserState } from "../../common/UserContext";
import { User } from "../../types/UserGroup";
import { updateUser } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";

const NOTIFIED_ORDER_IDS_KEY = "notifiedOrderIds";
export const DashboardView = () => {
    const [orderForm, setOrderForm] = useState<OrderForm>();
    const navigate = useNavigate();
    const { me } = useUserState();
    const [showUserGuide, setShowUserGuide] = useState(false);

    const loadExpiringOrder = async () => {
        const notifications = await getExpiringOrderNotifications();
        const notifiedOrderIds = JSON.parse(sessionStorage.getItem(NOTIFIED_ORDER_IDS_KEY) || "[]");
        const filteredNotifications = notifications.filter(
            (item) => !notifiedOrderIds.includes(item.resource.resourceId)
        );
        if (filteredNotifications.length) {
            const res = await getOrderForm(filteredNotifications[0].resource.resourceId);

            sessionStorage.setItem(
                NOTIFIED_ORDER_IDS_KEY,
                JSON.stringify([...notifiedOrderIds, res.orderForm.id])
            );
            setOrderForm(res.orderForm);
        }
    };

    const updateUserGuide = async () => {
        try {
            await updateUser({
                ...me,
                guided: true,
            } as User);
        } catch (error) {
            handleError(error);
        } finally {
            setShowUserGuide(false);
        }
    };

    useEffect(() => {
        loadExpiringOrder();
    }, []);

    useEffect(() => {
        if (!me.guided) {
            setShowUserGuide(true);
        }
    }, [me.guided]);

    const alert = () => {
        if (orderForm) {
            const key = `expiring-orderForm-${orderForm.id}`;
            notification.warning({
                message: (
                    <>
                        <div style={{ color: "#FF8400", fontWeight: 500 }}>
                            You have a contract expiring soon.
                        </div>
                        <div style={{ color: "#000000", fontSize: 14 }}>
                            Your contract “{orderForm.name}” is set to expire soon. Review its
                            Utilization measure to see how you can improve your contract.
                        </div>
                        <Space>
                            <Typography.Link onClick={() => notification.close(key)}>
                                Close
                            </Typography.Link>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => navigate(`/app/order/${orderForm.id}`)}
                            >
                                Go to contract
                            </Button>
                        </Space>
                    </>
                ),
                placement: "top",
                duration: 0,
                style: { width: 500 },
                key,
            });
        }
    };

    useEffect(() => {
        alert();
        return () => {
            notification.destroy();
        };
    }, [orderForm]);

    const panel = (
        <Card>
            <UserGuideModal
                open={showUserGuide}
                onCancel={() => setShowUserGuide(false)}
                onSkip={() => updateUserGuide()}
                onOk={() => updateUserGuide()}
            />
            <CostChartPanel />
            <TopSuppliersPanel style={{ marginTop: 24 }} />
            <VendorHeatmapPanel style={{ marginTop: 24 }} />
        </Card>
    );
    return (
        <MainLayout mainPanel={<>{panel}</>} selected={"Dashboard"} collapsed={true}></MainLayout>
    );
};
