import React, { useState, useEffect, createRef } from "react";
import MainLayout from "../navigation/MainLayout";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { Table, Tag, Row, Col, Input, Space, Button, InputRef } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { formatUTCDate } from "../date/DateOp";
import { listOrders } from "../../common/api/OrderFormClient";
import {
    Filter,
    FilterGroupType,
    FilterOperand,
    Pagination,
    Sort,
    sortOrderTranslate,
} from "../../types/DataFetchingTypes";
import { getVendors } from "../../common/api/VendorClient";
import { Vendor } from "../../types/VendorTypes";
import NumberFormat from "react-number-format";
import { getTags } from "../../common/api/TagClient";
import { ResourceType } from "../../types/ResourceType";
import { TaggedResource } from "../../types/TagTypes";
import { getMostRecentNotificationsForResource } from "../../common/api/NotificationsClient";
import { MostRecentNotificationForResource } from "../../types/NotificationTypes";
import { statusToTag } from "../notifications/NotificationTranslation";
import { orderFormFilterStatus, orderStatusToTag } from "./OrderStatus";
import { OrderForm } from "../../types/OrderFormTypes";
import { SortFilter } from "../../common/CommonInterfaces";
import { handleError } from "../../common/ErrorHandling";
import { PeriodDates } from "../date/TimePeriods";

const { Column } = Table;

const OrderFormsView: React.FC = () => {
    const [data, setData]: any[] = useState([]);
    const [createdOrder] = useState(undefined as undefined | number);
    const [pagination, setPagination] = useState({
        pages: 0,
        current: 1,
        total: 0,
        pageSize: 10,
    } as Pagination);

    const [sortFilter, setSortFilter] = useState({ sort: [], filter: [] } as SortFilter);
    const [loading, setLoading]: any[] = useState(true);
    const [vendors, setVendors] = useState({} as Map<string, Vendor>);
    const [tags, setTags] = useState({} as Map<number, TaggedResource>);
    const [reload, setReload]: any[] = useState(0);
    const [notificationsMap, setNotificationsMap] = useState(
        {} as Map<number, MostRecentNotificationForResource>
    );
    const [searchParams] = useSearchParams();

    let timespan: PeriodDates;
    if (searchParams.has("startDate") && searchParams.has("endDate")) {
        timespan = {
            startDate: new Date(searchParams.get("startDate") as string),
            endDate: new Date(searchParams.get("endDate") as string),
        };
    }

    const searchRef = createRef<InputRef>();

    const handleTableChange = async (pagination: any, filters_: any, sorter: any) => {
        setPagination(pagination);

        const fs = { filter: [], sort: [] } as SortFilter;

        if (sorter.columnKey !== undefined) {
            fs.sort = [
                { attribute: sorter.columnKey, order: sortOrderTranslate(sorter.order) } as Sort,
            ];
        }
        if (filters_.status) {
            const filters1 = filters_.status.map((v: string) => {
                return {
                    attribute: "status",
                    operand: FilterOperand.EQUALS,
                    value: v,
                } as Filter;
            });

            const groups = {
                groupId: "status",
                groupType: FilterGroupType.OR,
                filter: filters1,
            };

            fs.filter.push(groups);
        }
        if (filters_.name) {
            const names = filters_.name.map((v: string) => {
                return {
                    attribute: "name",
                    operand: FilterOperand.SIMILAR,
                    value: v,
                } as Filter;
            });
            const nameSearch = {
                groupId: "name",
                groupType: FilterGroupType.AND,
                filter: names,
            };
            fs.filter.push(nameSearch);
        }
        setSortFilter(fs);
        setReload(reload + 1);
    };

    const handleNameSearch = async (_selectedKeys: any, confirm: any) => {
        confirm({ closeDropdown: true });
    };

    const handleReset = (clearFilter: any) => {
        clearFilter();
    };

    useEffect(() => {
        setLoading(true);
        listOrders(
            pagination,
            sortFilter.sort,
            sortFilter.filter,
            undefined,
            undefined,
            undefined,
            undefined,
            timespan
        )
            .then(async (pager) => {
                if (pager.data.length > 0) {
                    const [vendors, tagged, notifications] = await Promise.all([
                        getVendors(pager.data.map((v) => v.vendorKey)),
                        getTags(
                            pager.data.map((v) => v.id),
                            ResourceType.OrderForm
                        ),
                        getMostRecentNotificationsForResource(
                            pager.data.map((v) => v.id),
                            ResourceType.OrderForm
                        ),
                    ]);
                    const vendorMap = new Map(vendors.map((v) => [v.vendorKey, v]));
                    const _notificationsMap = new Map(
                        notifications.map((v) => [v.resource.resourceId, v])
                    );
                    setNotificationsMap(_notificationsMap);
                    setVendors(vendorMap);
                    setTags(tagged);
                }

                setData(pager.data);
                setPagination(pager.pagination);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [reload, searchParams]);

    const mainPanel = (
        <div className="contract-panel" style={{ paddingTop: 10 }}>
            <Row key="contentTable">
                <Col span={24}>
                    <Table
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        rowKey="id"
                        onChange={handleTableChange}
                    >
                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                            sorter={true}
                            render={(value, row: OrderForm) => {
                                return <Link to={"/app/order/" + row.id + ""}>{value}</Link>;
                            }}
                            filterIcon={<SearchOutlined />}
                            onFilter={(_value, _record) => {
                                return true;
                            }}
                            filterDropdown={({
                                setSelectedKeys,
                                selectedKeys,
                                confirm,
                                clearFilters,
                            }) => {
                                return (
                                    <div style={{ padding: 8 }}>
                                        <Input
                                            ref={searchRef}
                                            placeholder={`Search `}
                                            value={selectedKeys[0]}
                                            onChange={(e) =>
                                                setSelectedKeys(
                                                    e.target.value ? [e.target.value] : []
                                                )
                                            }
                                            onPressEnter={() => {
                                                handleNameSearch(selectedKeys, confirm);
                                            }}
                                            style={{ marginBottom: 8, display: "block" }}
                                        />
                                        <Space>
                                            <Button
                                                type="primary"
                                                onClick={() =>
                                                    handleNameSearch(selectedKeys, confirm)
                                                }
                                                icon={<SearchOutlined />}
                                                size="small"
                                                style={{ width: 90 }}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                onClick={() => handleReset(clearFilters)}
                                                size="small"
                                                style={{ width: 90 }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                type="link"
                                                size="small"
                                                onClick={() => {
                                                    confirm({ closeDropdown: true });
                                                }}
                                            >
                                                Close
                                            </Button>
                                        </Space>
                                    </div>
                                );
                            }}
                            onFilterDropdownVisibleChange={(visible) => {
                                if (visible) {
                                    setTimeout(() => searchRef.current?.focus(), 100);
                                }
                            }}
                        />

                        <Column
                            title="Vendor"
                            dataIndex="vendorKey"
                            key="vendorKey"
                            sorter={true}
                            render={(value) => {
                                const vendor = vendors.get(value);
                                return (
                                    <Link to={`/app/vendor/${vendor?.vendorKey}`}>
                                        {vendor?.name}
                                    </Link>
                                );
                            }}
                        />

                        <Column
                            title="Renewal date"
                            dataIndex="expirationDate"
                            key="expirationDate"
                            sorter={true}
                            render={(value) => {
                                return formatUTCDate(value);
                            }}
                        />
                        <Column
                            title="Value"
                            dataIndex="value"
                            key="value"
                            sorter={true}
                            render={(value) => {
                                return (
                                    <NumberFormat
                                        value={value}
                                        thousandSeparator={true}
                                        displayType={"text"}
                                        prefix={"$"}
                                    />
                                );
                            }}
                        />

                        <Column
                            title="Status"
                            dataIndex="status"
                            key="status"
                            filters={orderFormFilterStatus}
                            render={(value) => {
                                return orderStatusToTag(value);
                            }}
                        />

                        <Column
                            title="Notifications"
                            dataIndex="notifications"
                            key="notifications"
                            render={(_value, row: OrderForm) => {
                                const notification = notificationsMap.get(row.id);
                                if (notification !== undefined) {
                                    return statusToTag(notification.notification.status);
                                }
                                return statusToTag(undefined);
                            }}
                        />

                        <Column
                            title="Tags"
                            dataIndex="tags"
                            key="tags"
                            render={(_value, row: OrderForm) => {
                                return (
                                    <>
                                        {tags.get(row.id)?.tags.map((t, idx) => (
                                            <Tag
                                                key={`${row.id}-${idx}-${t.name}-tags-${row.id}-${t.color}`}
                                                color={t.color}
                                            >
                                                {t.name}
                                            </Tag>
                                        ))}
                                    </>
                                );
                            }}
                        />
                    </Table>
                </Col>
            </Row>
        </div>
    );

    return createdOrder ? (
        <Navigate to={`/app/order/${createdOrder}`} />
    ) : (
        <MainLayout collapsed={true} selected={"order"} mainPanel={mainPanel} />
    );
};

export default OrderFormsView;
