import React, { ReactNode } from "react";
import "./MatchScore.css";

type VariantType = "success" | "warning" | "danger";

type SizeType = "middle" | "small";

interface MatchScoreProps {
    type?: VariantType;
    size?: SizeType;
    title?: ReactNode;
    score?: ReactNode;
}

const PREFIX = "MatchScore";

export const MatchScore: React.FC<MatchScoreProps> = ({
    type = "success",
    size = "middle",
    title,
    score,
}) => {
    const topClassName = `${PREFIX} ${PREFIX}--${type} ${PREFIX}--${size}`;

    return (
        <div className={topClassName}>
            <div className={`${PREFIX}__content`}>
                <div className={`${PREFIX}__content-title`}>{title}</div>
                <div className={`${PREFIX}__content-score`}>{score}</div>
            </div>
        </div>
    );
};
