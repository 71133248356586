import { TimeQuarter } from "../../types/TimeTypes";
import { PeriodDates } from "../../components/date/TimePeriods";
import {
    BudgetSetting,
    CumulativeOrderValue,
    IndustryStatistics,
    OrderExpirationYear,
    TopSupplier,
} from "../../types/DashboardTypes";
import { safeGET, safePUT } from "./SafeClient";
import { convertDateToString } from "../DateUtil";

export function getCostData(year: number, abort?: any): Promise<CumulativeOrderValue[]> {
    return safeGET(`/api/dashboard/v2/cost?year=${year}`, abort);
}

export function getTopSuppliers(quarter?: TimeQuarter, abort?: any): Promise<TopSupplier[]> {
    const quarterQuery = quarter ? `?year=${quarter.year}&quarter=${quarter.quarter}` : "";

    return safeGET(`/api/dashboard/v2/supplier${quarterQuery}`, abort);
}

export function getIndustryStatistics(
    timespan: PeriodDates,
    abort?: any
): Promise<IndustryStatistics[]> {
    const startDate = convertDateToString(timespan.startDate);
    const endDate = convertDateToString(timespan.endDate);

    return safeGET(`/api/dashboard/v2/industry?startDate=${startDate}&endDate=${endDate}`, abort);
}

export function getBudgetSetting(years: number[]): Promise<BudgetSetting[]> {
    const yearQuery = years.map((year) => `year[]=${year}`).join("&");
    return safeGET(`/api/dashboard/v2/budget?${yearQuery}`);
}

export function updateBudgetSetting(budget: BudgetSetting): Promise<BudgetSetting> {
    return safePUT(`/api/dashboard/v2/budget`, budget);
}

export function getOrderExpirationYear(abort?: any): Promise<OrderExpirationYear[]> {
    return safeGET(`/api/dashboard/v2/years`, abort);
}
