import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row, Space } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { GenericModal, GenericModalProps } from "./GenericModal";
import { ReactComponent as IntelligenceMain } from "..//../assets/icons/svg/thinker-gradient.svg";
import { ReactComponent as IntelligenceInsight } from "../../assets/icons/svg/insights.svg";
import { ReactComponent as ModalClose } from "../../assets/icons/svg/ModalClose.svg";
import "./IntelligenceModal.less";
import { PeriodDates } from "../../components/date/TimePeriods";
import { TimeUnit } from "../../types/TimeTypes";
import { getTimeOfDay } from "../../components/date/DateOp";
import { IntelligenceModalFooter } from "./IntelligenceModalFooter";

interface IntelligenceModalProps extends GenericModalProps {
    name?: string;
    expiringCount?: number;
    timespan?: PeriodDates;
}

const PREFIX = "IntelligenceModal";

const time = getTimeOfDay();

export const IntelligenceModal: React.FC<IntelligenceModalProps> = ({
    className,
    name = "",
    expiringCount = 0,
    timespan,
    ...restProps
}) => {
    const classes = [`${PREFIX}`, className].join(" ").trim();

    const title = (
        <Row align="middle">
            <Col>
                <QuestionCircleFilled />
            </Col>
            <Col flex={1} style={{ textAlign: "center" }}>
                <IntelligenceMain />
            </Col>
            <Col onClick={restProps.onCancel}>
                <ModalClose />
            </Col>
        </Row>
    );

    const insights = [
        {
            title: "Contracts expiring soon",
            detail: `You have ${expiringCount} contracts expiring in the next month.`,
            link: `/app/order/list${
                timespan ? `?startDate=${timespan.startDate}&endDate=${timespan.endDate}` : ""
            }`,
        },
        {
            title: "Spend path",
            detail: "See your current monthly spend.",
            link: `/app/calendar?timeUnit=${TimeUnit.DAY}`,
        },
    ];

    const content = (
        <div className={`${PREFIX}-content`}>
            <div className={`${PREFIX}-content-welcome`}>{`Good ${time} ${name},`}</div>
            <Space size={12} className={`${PREFIX}-content-overview`}>
                <IntelligenceInsight width={20} height={20} fill="#FF981F" />
                <span>I have some insights for you.</span>
            </Space>
            {insights.map((item) => (
                <Row className={`${PREFIX}-content-insight`} key={item.title} align="middle">
                    <Col flex={1}>
                        <div className={`${PREFIX}-content-insight__title`}>{item.title}</div>
                        <div className={`${PREFIX}-content-insight__detail`}>{item.detail} </div>
                    </Col>
                    <Col>
                        <Link to={item.link}>
                            <Button type="primary">View</Button>
                        </Link>
                    </Col>
                </Row>
            ))}
        </div>
    );

    return (
        <GenericModal
            {...restProps}
            className={classes}
            title={title}
            closable={false}
            footer={<IntelligenceModalFooter />}
        >
            {content}
        </GenericModal>
    );
};
