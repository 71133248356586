import { Form, Modal, ModalProps, Select, Input } from "antd";
import { useEffect, useState } from "react";
import { Industry } from "../../../types/IndustryTypes";
import { Category } from "../IndustryView";

export interface IndustryManageModalProps extends Omit<ModalProps, "onOk"> {
    industry?: Industry;
    sectors?: Industry[];
    onOk?: (user: Industry) => void;
    category?: Category;
}

export const IndustryManageModal: React.FC<IndustryManageModalProps> = ({
    industry,
    sectors,
    category,
    onOk,
    ...restProps
}) => {
    const [industryCopy, setIndustryCopy] = useState({} as Industry);

    useEffect(() => {
        industry && setIndustryCopy(industry);
    }, [industry]);

    const modal = (
        <Modal
            title={"Update Industry"}
            {...restProps}
            onOk={async () => {
                onOk?.(industryCopy);
            }}
        >
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} layout="horizontal" size="small">
                {category === Category.Industry ? (
                    <Form.Item label="Sector">
                        <Select
                            value={industryCopy?.pid}
                            options={sectors?.map((item) => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            onChange={(value) => {
                                setIndustryCopy((old) => ({
                                    ...old,
                                    pid: value,
                                }));
                            }}
                        />
                    </Form.Item>
                ) : null}
                <Form.Item label="Name">
                    <Input
                        value={industryCopy?.name}
                        onChange={(e) => {
                            setIndustryCopy((old) => ({
                                ...old,
                                name: e.target.value,
                            }));
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
    return modal;
};
