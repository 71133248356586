import React from "react";
import { Drawer, DrawerProps, List, Typography } from "antd";
import { Attachment } from "../../types/AttachmentTypes";

export interface AttachmentsDrawerProps extends DrawerProps {
    attachments?: Attachment[];
    onDownload?: (attachment: Attachment) => void;
}

export const AttachmentsDrawer: React.FC<AttachmentsDrawerProps> = ({
    attachments,
    onDownload,
    ...restProps
}) => {
    const content = (
        <List
            dataSource={attachments}
            renderItem={(item) => (
                <List.Item>
                    <Typography.Link onClick={() => onDownload?.(item)}>
                        {item.filename}
                    </Typography.Link>
                </List.Item>
            )}
        />
    );

    return (
        <Drawer title="Attachments" {...restProps}>
            {content}
        </Drawer>
    );
};
