import React from "react";
import ReactECharts from "echarts-for-react";
import { CostChartOptionProps, getCostChartOption } from "./CostChartOption";

export interface EventHandlerParams {
    componentType: string;
    name: string;
    value: number;
    [key: string]: unknown;
}

interface CostChartProps extends CostChartOptionProps {
    onMarkLineClick?: (params: EventHandlerParams) => void;
    onSeriesLineClick?: (params: EventHandlerParams) => void;
}

export const CostChart: React.FC<CostChartProps> = (props) => {
    const option = getCostChartOption(props);

    const handleClick = (params: EventHandlerParams) => {
        if (params.componentType === "markLine") {
            props.onMarkLineClick?.(params);
            return;
        }
        if (params.componentType === "series") {
            props.onSeriesLineClick?.(params);
            return;
        }
    };

    return (
        <ReactECharts
            style={{
                width: "100%",
                minHeight: 542,
                borderRadius: 8,
                overflow: "hidden",
            }}
            option={option}
            onEvents={{
                click: handleClick,
            }}
        />
    );
};
