import { AccessRight } from "./AccessControlTypes";
import { RawLever } from "./NegotiationTypes";
import { TimeUnit } from "./TimeTypes";

export enum OrderFormStatus {
    EXPIRATION_NOT_SET = "not_set",
    RENEWAL_SCHEDULED = "renewal_scheduled",
    WILL_NOT_RENEW = "will_not_renew",
    OVERDUE = "overdue",
    RENEWED = "renewed",
}

export enum OrderFormProcessingStatus {
    NONE = "none",
    PROCESSING = "processing",
    COMPLETE = "complete",
    CANCELLED = "cancelled",
}

export const OrderFormType = "OrderForm";

export interface OrderForm {
    id: number;
    name: string;
    status: OrderFormStatus;
    expirationDate?: Date;
    orderStartDate?: Date;
    recordCreationDate: Date;
    orderEndDate?: Date;
    migrationLength?: number;
    value: number;
    autoRenewal: boolean;
    vendorKey: string;
    creatorId: number;
    ownerId: number;
    businessOwnerEmail: string;
    processingStatus: OrderFormProcessingStatus;
    dealRating: DealRating;
    category: string;
    legalWeight: number;
    centsValue: number;
    utilizationScore: number;
    orderScore: number;
    increase?: number;
    decrease?: number;
    [key: string]: any;
}

export interface OrderFormSKU {
    id: number;
    orderFormId: number;
    name: string;
    sku: string;
    quantity: number;
    price: number;
    totalPrice: number;
    usage: number;
}

export interface OrderFormResponse {
    orderForm: OrderForm;
    access: AccessRight;
}

export interface OrderFormUpdateResponse extends OrderForm {}

export interface OrderFormUpdateRequest {
    id: number;
    name?: string;
    status?: OrderFormStatus;
    expirationDate?: Date;
    orderStartDate?: Date;
    orderEndDate?: Date;
    migrationLength?: number;
    value?: number;
    autoRenewal?: Boolean;
    vendorKey?: string;
    ownerId?: number;
    businessOwnerEmail?: string;
    dealRating: DealRating;
    category: string;
}

export enum DealRating {
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5,
    F = 6,
    NONE = 0,
}
export enum OFOPerationType {
    ADD = "ADD",
    REMOVE = "REMOVE",
}

export interface OrderFormGroupOperation {
    type: OFOPerationType;
    values: number[];
}

export interface OrderFormGroupOperationResponse {
    type: OFOPerationType;
    values: number[];
}

export interface DashboardVendorValue {
    vendorKey: string;
    orderForms: OrderForm[];
}

export interface DashboardVendorTimeSeries {
    unit: string;
    values: DashboardVendorValue[];
}
export interface DashboardVendorTimeAggregate {
    timeUnit: TimeUnit;
    timeSeries: DashboardVendorTimeSeries[];
}

export interface OrderFormLever {
    id: number;
    leverId: number;
    orderFormId: number;
    selectedOptionId: number;
    recordCreationDate: Date;
}

export interface OrderFormLeverDetail extends OrderFormLever {
    isSelected: boolean;
    lever: RawLever;
}

export interface OrderFormAnalysis {
    id: number;
    orderFormId: number;
    analysisId: number;
    selectedOptionId: number;
}

export interface Analysis {
    id: number;
    title: string;
    description: string;
    score: number;
    options: AnalysisOption[];
}

export interface AnalysisOption {
    id: number;
    title: string;
    weight: number;
}

export interface OrderFormAnalysisResponse extends Partial<OrderFormAnalysis> {
    analysis: Analysis;
}
