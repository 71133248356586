import { OrderForm, OrderFormSKU } from "./OrderFormTypes";

export enum NegotiationStatusCategory {
    EXISTING = "existing",
    PAST = "past",
}

export interface CotermInsight {
    before: number;
    after: number;
    reduce: number;
    orderForms: OrderForm[];
}

export interface ChangedOrderSKU extends OrderFormSKU {
    orderFormName: string;
    vendorKey: string;
    pastPrice: number;
    delta: number;
}

export interface PriceDiffInsight {
    vendorNumber: number;
    skuNumber: number;
    discrepancy: number;
    changedOrderSKUs: ChangedOrderSKU[];
}

export interface OrderFormEndDates extends OrderForm {
    quarterEnd: string;
    yearEnd: string;
}

export interface EndDatesInsight {
    total: number;
    earlyEnd: number;
    normalEnd: number;
    orderForms: OrderFormEndDates[];
}

export interface NegotiationInsigtsType {
    coterm: CotermInsight;
    priceDiff: PriceDiffInsight;
    endDates: EndDatesInsight;
}

export enum NegotiationStatus {
    NOT_STARTED = "not_started",
    IN_PROGRESS = "in_progress",
    COMPLETE = "complete",
    CANCELLED = "cancelled",
}

export interface Negotiation {
    id: number;
    name: string;
    status: NegotiationStatus;
    expirationDate?: Date;
    recordCreationDate: Date;
    vendorKey: string;
    value: number;
    creatorId: number;
    ownerId: number;
    category: string;
    legalWeight: number;
    simulationScore: number;
    [key: string]: any;
}

export interface RawLeverOption {
    id: number;
    weight: number;
    label: string;
    outcome?: string;
    best?: string;
}

export enum NegotiationLeversCategory {
    PROCUREMENT_LEVERS = "procurement_levers",
    LEGAL_LEVERS = "legal_levers",
}

export interface RawLever {
    id: number;
    name: string;
    weight: number;
    options: RawLeverOption[];
    outcome?: string;
    best?: string;
    category: NegotiationLeversCategory;
}

export interface NegotiationLever {
    id: number;
    leverId: number;
    negotiationId: number;
    selectedOptionId: number;
    recordCreationDate: Date;
}

export interface NegotiationLeverDetail extends NegotiationLever {
    isSelected: boolean;
    lever: RawLever;
}

export interface AverageSimulationScore {
    average: number;
}

export interface LeverDetail extends Omit<NegotiationLeverDetail, "negotiationId"> {}

export interface CommonLever extends Omit<NegotiationLever, "negotiationId"> {}
