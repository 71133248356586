import React from "react";
import { Button } from "antd";
import { getPdfFile } from "../api/OrderFormClient";
import { SvgIcon } from "./SvgIcon";

interface OrderFormDownloadButtonProps {
    orderFormId: number;
}

export const OrderFormDownloadButton: React.FC<OrderFormDownloadButtonProps> = ({
    orderFormId,
}) => {
    const onClickForm = async () => {
        const res: any = await getPdfFile(orderFormId);
        if (res && "url" in res) {
            window.open(res.url, "_blank");
        }
    };

    return (
        <Button
            type="link"
            size={"small"}
            onClick={onClickForm}
            icon={<SvgIcon name="download" />}
        ></Button>
    );
};
