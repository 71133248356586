import React, { useEffect, useState } from "react";
import { Checkbox, Space, Rate, Tabs, Slider, UploadFile } from "antd";
import { GenericModal, GenericModalProps } from "../../../common/components/GenericModal";
import { OrderFormAnalysisResponse, OrderFormSKU } from "../../../types/OrderFormTypes";
import { UtilizationMeasureModalFooter } from "./UtilizationMeasureModalFooter";

enum SurveyType {
    SENTIMENT = "sentiment",
    SKU = "sku",
    SCORE = "score",
}

interface UtilizationMeasureModalProps extends Omit<GenericModalProps, "onOk"> {
    sentiment?: OrderFormAnalysisResponse[];
    skus?: OrderFormSKU[];
    score?: number;
    onOk?: (
        sentiment?: OrderFormAnalysisResponse[],
        skus?: OrderFormSKU[],
        score?: number,
        comment?: string,
        files?: UploadFile[]
    ) => Promise<void>;
}

export const UtilizationMeasureModal: React.FC<UtilizationMeasureModalProps> = ({
    sentiment,
    skus,
    score = 0,
    onOk,
    ...restProps
}) => {
    const [sentimentCopy, setSentimentCopy] = useState<OrderFormAnalysisResponse[]>();
    const [skuCopy, setSkuCopy] = useState<OrderFormSKU[]>();
    const [utilizationScore, setUtilizationScore] = useState(0);
    const [currentTab, setCurrentTab] = useState<string>(SurveyType.SENTIMENT);

    useEffect(() => {
        setSentimentCopy(sentiment);
    }, [sentiment]);

    useEffect(() => {
        setSkuCopy(skus);
    }, [skus]);

    useEffect(() => {
        setUtilizationScore(score);
    }, [score]);

    const handleSentimentChange = (analysisId: number, optionId: number, checked: boolean) => {
        setSentimentCopy(
            sentimentCopy?.map((item) => {
                if (item.analysisId === analysisId) {
                    return {
                        ...item,
                        selectedOptionId: checked ? optionId : 0,
                    };
                }
                return item;
            })
        );
    };

    const handleSKUChange = (skuId: number, usage: number) => {
        setSkuCopy((pre) => {
            return pre?.map((item) => {
                if (item.id === skuId) {
                    return {
                        ...item,
                        usage,
                    };
                }
                return item;
            });
        });
    };

    const handleOk = async (comment?: string, files?: UploadFile[]) => {
        const changedSentiment = sentimentCopy?.filter((item) => {
            const original = sentiment?.find((ele) => ele.analysisId === item.analysisId);
            return original?.selectedOptionId !== item.selectedOptionId;
        });
        const changedSku = skuCopy?.filter((item) => {
            const original = skus?.find((ele) => ele.id === item.id);
            return original?.usage !== item.usage;
        });

        const changedScore = utilizationScore !== score ? utilizationScore : undefined;
        await onOk?.(changedSentiment, changedSku, changedScore, comment, files);
    };

    const sentimentSurvey = (
        <Space direction="vertical" style={{ width: "100%" }}>
            {sentimentCopy?.map((item) => (
                <div key={item.analysis.id}>
                    <div style={{ color: "#BFBFBF" }}>{item.analysis.title}</div>
                    <div style={{ color: "#BFBFBF", marginTop: 8, marginBottom: 10 }}>
                        {item.analysis.description}
                    </div>
                    <Checkbox.Group value={[item.selectedOptionId || 0]}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {item.analysis.options?.map((option) => (
                                <Checkbox
                                    value={option.id}
                                    key={option.id}
                                    style={{ color: "#BFBFBF" }}
                                    onChange={(value) =>
                                        handleSentimentChange(
                                            item.analysis.id,
                                            option.id,
                                            value.target.checked
                                        )
                                    }
                                >
                                    {option.title}
                                </Checkbox>
                            ))}
                        </Space>
                    </Checkbox.Group>
                </div>
            ))}
        </Space>
    );

    const skuSurvey = (
        <Space direction="vertical" style={{ width: "100%" }}>
            {skuCopy?.map((item) => (
                <div key={item.id}>
                    <div style={{ color: "#BFBFBF" }}>{item.name}</div>
                    <Slider
                        value={item.usage}
                        onChange={(value) => handleSKUChange(item.id, value)}
                    ></Slider>
                </div>
            ))}
        </Space>
    );

    const utilizationScoreSurvey = (
        <div>
            <div style={{ color: "#BFBFBF" }}>Utilization score</div>
            <Rate
                value={utilizationScore}
                count={10}
                style={{ color: "#1890FF" }}
                onChange={setUtilizationScore}
            ></Rate>
        </div>
    );

    return (
        <GenericModal
            {...restProps}
            title="Determine your Utilization measure"
            width={650}
            footer={<UtilizationMeasureModalFooter onOk={handleOk} onCancel={restProps.onCancel} />}
        >
            <div style={{ fontWeight: 500, color: "#666666" }}>
                Answer built in surveys around sentiment, SKU usage, and utilization measures to
                identify gaps, eliminate inefficiencies, and unlock cost-saving opportunities.
            </div>
            <Tabs
                items={[
                    { label: "Sentiment analysis", key: SurveyType.SENTIMENT },
                    { label: "SKU usage", key: SurveyType.SKU },
                    { label: "Utilization Score", key: SurveyType.SCORE },
                ]}
                style={{ color: "#BFBFBF" }}
                onChange={setCurrentTab}
            />
            {currentTab === SurveyType.SENTIMENT && sentimentSurvey}
            {currentTab === SurveyType.SKU && skuSurvey}
            {currentTab === SurveyType.SCORE && utilizationScoreSurvey}
        </GenericModal>
    );
};
