import React from "react";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as WebSvg } from "../../assets/icons/svg/Web.svg";
import { ReactComponent as LinkedinSvg } from "../../assets/icons/svg/Linkedin.svg";
import { ReactComponent as TwitterSvg } from "../../assets/icons/svg/Twitter.svg";
import { getFullUrl } from "../Url";
import { CompanyImage } from "./CompanyImage";

interface CompanyImageCellProps {
    logo?: string;
    name: string;
    link?: string;
    linkTarget?: string;
    domain?: string;
    linkedin?: string;
    twitter?: string;
}

export const CompanyImageCell: React.FC<CompanyImageCellProps> = ({
    logo,
    name,
    link,
    linkTarget,
    domain,
    linkedin,
    twitter,
}) => {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", gap: 16 }}>
            <Space style={{ flex: 1 }}>
                <CompanyImage size="small" logo={logo} name={name} />
                <span style={{ whiteSpace: "nowrap" }}>
                    {link ? (
                        <Link to={link} target={linkTarget}>
                            {name}
                        </Link>
                    ) : (
                        name
                    )}
                </span>
            </Space>
            <Space>
                {domain && (
                    <a
                        href={getFullUrl(domain)}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-domain"
                    >
                        <WebSvg />
                    </a>
                )}
                {linkedin && (
                    <a
                        href={linkedin}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-linkedin"
                    >
                        <LinkedinSvg />
                    </a>
                )}
                {twitter && (
                    <a
                        href={twitter}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-twitter"
                    >
                        <TwitterSvg />
                    </a>
                )}
            </Space>
        </div>
    );
};
