import React from "react";
import ReactECharts from "echarts-for-react";

export const SKU_COLORS = [
    "#006ACC",
    "#FF8400",
    "#FD3559",
    "#EA00FF",
    "#4FBD19",
    "#1890FF",
    "#FF981F",
    "#FD6883",
    "#F03FFF",
    "#5AD81D",
    "#99CEFF",
    "#FFCA99",
    "#FE9AAC",
    "#F799FF",
    "#C1F2A9",
    "#E6F3FF",
    "#FFECDB",
    "#FFE6EA",
    "#FCDBFF",
    "#E9FAE0",
    "#F0F8FF",
    "#FFF4EB",
    "#FFF0F2",
    "#FDEBFF",
    "#F2FCED",
];

export const getSkuColor = (index: number) => {
    const idx = index % SKU_COLORS.length;
    return SKU_COLORS[idx];
};

export interface SkuUsageChartProps {
    usage: number;
    data: Array<{ value: number; name: string }>;
}

export const SkuUsageChart: React.FC<SkuUsageChartProps> = ({ usage, data }) => {
    return (
        <ReactECharts
            style={{ width: "100%", height: "100%" }}
            option={{
                tooltip: {
                    trigger: "item",
                },
                title: {
                    text: `${usage}`,
                    subtext: "SKUs used",
                    left: "center",
                    top: "33%",
                    textStyle: {
                        fontSize: 32,
                        fontWeight: 700,
                        fontFamily: "Inter",
                        color: "#000000",
                        lineHeight: 39,
                    },
                    subtextStyle: {
                        fontFamily: "Inter",
                        fontSize: 14,
                        color: "#666666",
                    },
                    itemGap: 0,
                },
                series: [
                    {
                        type: "pie",
                        radius: ["55%", "95%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                        },
                        emphasis: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: data.map((item, index) => ({
                            ...item,
                            itemStyle: { color: getSkuColor(index) },
                        })),
                    },
                ],
            }}
        ></ReactECharts>
    );
};
