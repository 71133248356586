import { Table } from "antd";
import React from "react";
import { getFullUrl } from "../../../common/Url";
import {
    getLogoBoxTypeByRank,
    SupplierCompetitorDifferentiator,
    SupplierFullCompetitor,
} from "../../../types/SupplierTypes";
import { CompanyName } from "./CompanyName";

interface CompetitorsTableProps {
    data: SupplierFullCompetitor[];
}

export const CompetitorsTable: React.FC<CompetitorsTableProps> = ({ data }) => {
    const columns = [
        {
            title: "Company name",
            dataIndex: "name",
            key: "name",
            render: (value: string, row: SupplierFullCompetitor) => {
                return (
                    <CompanyName
                        name={value}
                        link={getFullUrl(row.website)}
                        linkTarget="_blank"
                        logo={row.logo}
                        logoBoxType={getLogoBoxTypeByRank(data.indexOf(row) + 1)}
                    />
                );
            },
        },
        {
            title: "Key products",
            dataIndex: "keyOfferings",
            key: "keyProducts",
            render: (value: string) => {
                return value;
            },
        },
        {
            title: "Key differentiators",
            dataIndex: "keyDifferentiators",
            key: "KeyDifferentiators",
            render: (value: SupplierCompetitorDifferentiator[]) => {
                return (
                    <ul style={{ paddingLeft: "1rem", marginBottom: 0 }}>
                        {value?.map((item) => (
                            <li key={item.id}>
                                <span style={{ fontWeight: 500 }}>{item.name}: </span>
                                {item.description}
                            </li>
                        ))}
                    </ul>
                );
            },
        },
    ];

    return <Table dataSource={data} rowKey="name" columns={columns} pagination={false} />;
};
