import React, { SVGProps, useEffect, useRef, useState } from "react";

interface SvgIconProps extends SVGProps<SVGSVGElement> {
    name: string;
    color?: string;
    size?: number;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
    name,
    size = 16,
    color = "currentColor",
    ...restProps
}) => {
    const iconRef = useRef(null as unknown as React.FC<SVGProps<SVGSVGElement>>);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const importIcon = async () => {
        setLoading(true);
        try {
            const res = await import(
                `!!@svgr/webpack?svgo=false!../../assets/icons/svg/${name}.svg`
            );
            iconRef.current = res.default;
        } catch (err) {
            console.error(err);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        importIcon();
    }, [name]);

    if (hasError) {
        return null;
    }

    if (!loading && iconRef.current) {
        const { current: ImportedIcon } = iconRef;
        return (
            <ImportedIcon
                width={size}
                height={size}
                fill={color}
                style={{ verticalAlign: "middle" }}
                {...restProps}
            />
        );
    }

    return null;
};
