import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Drawer,
    Form,
    Input,
    Row,
    Space,
    Tooltip,
    Typography,
    Image,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getCompany } from "../../common/api/CompanyClient";
import { Supplier } from "../../types/SupplierTypes";
import { createSupplier } from "../../common/api/SupplierClient";
import { handleError } from "../../common/ErrorHandling";
import { FullPanelSpin } from "./components/FullPanelSpin";

interface SupplierGeneratorPanelProps {
    open?: boolean;
    alwaysShow?: boolean;
    onClose?: () => void;
    onCreated?: (supplier: Supplier) => void;
    onAlwaysShowChange?: (checked: boolean) => void;
}

export const SupplierGenratorPanel: React.FC<SupplierGeneratorPanelProps> = ({
    open,
    alwaysShow,
    onClose,
    onCreated,
    onAlwaysShowChange,
}) => {
    const [supplier, setSupplier] = useState({} as Supplier);
    const [isDetailEditable, setIsDetailEditable] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const disabledFormItemClass = isDetailEditable ? "" : "disabledFormItem";

    const generateButtonDisabled = !Boolean(supplier.description);

    const fetchCompany = async () => {
        if (!supplier.name || !supplier.website) return;
        try {
            setShowSpinner(true);
            const res = await getCompany(supplier.name, supplier.website);
            const newSupplier = {
                name: res.name,
                description: res.descriptionShort,
                logo: res.logo,
                address: res.extend.headquartersAddress,
                symbol: res.stockSymbol,
                website: res.domain,
                linkedIn: res.socialNetworks.linkedin,
                twitter: res.socialNetworks.twitter,
                industries: res.industries.join(", "),
                keyOfferings: res.extend.keyOfferings.join(", "),
                keyBusinessDrivers: res.extend.keyBusinessDrivers.join(", "),
                esgScore: res.extend.esgScore,
                esgRanking: res.extend.esgRanking,
                competitorNames: res.extend.top3CompetitorNames.join(", "),
            } as Supplier;
            setSupplier(newSupplier);
            setIsDetailEditable(true);
        } catch (error) {
            handleError(error);
        } finally {
            setShowSpinner(false);
        }
    };

    const addSupplier = async () => {
        try {
            setShowSpinner(true);
            const res = await createSupplier(supplier);
            onCreated?.(res);
        } catch (error) {
            handleError(error);
        } finally {
            setShowSpinner(false);
        }
    };

    const namePrefix = supplier.logo ? (
        <div style={{ width: 20 }}>
            <Image src={supplier.logo} />
        </div>
    ) : null;

    const content = (
        <div>
            <Typography.Title level={5} style={{ fontWeight: 400 }}>
                Enter a company name in order to create your Buyer brief.
            </Typography.Title>
            <Form layout="vertical">
                <Form.Item label="Company name">
                    <Input
                        value={supplier.name}
                        placeholder="Enter..."
                        prefix={namePrefix}
                        onChange={(e) => {
                            setSupplier({
                                ...supplier,
                                name: e.target.value,
                            });
                        }}
                        onPressEnter={fetchCompany}
                    />
                </Form.Item>
                <Form.Item label="Website">
                    <Input
                        value={supplier.website}
                        placeholder="Enter..."
                        onChange={(e) => {
                            setSupplier({
                                ...supplier,
                                website: e.target.value,
                            });
                        }}
                        onPressEnter={fetchCompany}
                    />
                </Form.Item>
                <Form.Item label="Description" className={disabledFormItemClass}>
                    <Input.TextArea
                        value={supplier.description}
                        rows={4}
                        disabled={!isDetailEditable}
                        onChange={(e) => {
                            setSupplier({
                                ...supplier,
                                description: e.target.value,
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Key business drivers" className={disabledFormItemClass}>
                    <Input
                        value={supplier.keyBusinessDrivers}
                        disabled={!isDetailEditable}
                        onChange={(e) => {
                            setSupplier({
                                ...supplier,
                                keyBusinessDrivers: e.target.value,
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Key products/offerings" className={disabledFormItemClass}>
                    <Input
                        value={supplier.keyOfferings}
                        disabled={!isDetailEditable}
                        onChange={(e) => {
                            setSupplier({
                                ...supplier,
                                keyOfferings: e.target.value,
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Top 3 competitors" className={disabledFormItemClass}>
                    <Input
                        value={supplier.competitorNames}
                        disabled={!isDetailEditable}
                        onChange={(e) => {
                            setSupplier({
                                ...supplier,
                                competitorNames: e.target.value,
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Supplier + ESG Score" className={disabledFormItemClass}>
                    <Input
                        value={supplier.esgScore}
                        disabled={!isDetailEditable}
                        onChange={(e) => {
                            setSupplier({
                                ...supplier,
                                esgScore: Number(e.target.value),
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={alwaysShow}
                        onChange={(e) => onAlwaysShowChange?.(e.target.checked)}
                    >
                        Always show Buyer brief generator.
                    </Checkbox>
                </Form.Item>
            </Form>
        </div>
    );
    return (
        <Drawer
            title="New Buyer brief"
            placement="right"
            width={380}
            open={open}
            onClose={onClose}
            extra={
                <Tooltip
                    title={
                        "Conduct comprehensive due diligence on any company worldwide, whether publicly traded or private, using advanced AI insights."
                    }
                    placement="left"
                >
                    <ExclamationCircleOutlined />
                </Tooltip>
            }
            footer={
                <Row justify="end">
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            type="primary"
                            disabled={generateButtonDisabled}
                            onClick={addSupplier}
                        >
                            Generate Buyer brief
                        </Button>
                    </Space>
                </Row>
            }
        >
            {showSpinner && <FullPanelSpin />}
            {content}
        </Drawer>
    );
};
