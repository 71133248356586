import React from "react";
import { Modal, ModalProps } from "antd";
import { ReactComponent as ModalClose } from "../../assets/icons/svg/ModalClose.svg";
import "./GenericModal.less";

export interface GenericModalProps extends ModalProps {}

const PREFIX = "GenericModal";

export const GenericModal: React.FC<GenericModalProps> = ({
    className,
    children,
    ...restProps
}) => {
    const classes = [PREFIX, className].join(" ").trim();
    return (
        <Modal
            {...restProps}
            closeIcon={<ModalClose />}
            className={classes}
            maskStyle={{ backdropFilter: "blur(4px)", background: "rgba(20, 30, 46, 0.8)" }}
        >
            {children}
        </Modal>
    );
};
