import { useEffect, useState } from "react";
import { Divider, Form, Input, InputNumber, Popconfirm, Space, Table, Typography } from "antd";
import { deleteSKU, getSkus, updateSKU } from "../../common/api/OrderFormClient";
import { handleError } from "../../common/ErrorHandling";
import { OrderFormSKU } from "../../types/OrderFormTypes";
import { getSkuColor } from "../../components/orderform/components/SkuUsageChart";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: OrderFormSKU;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

interface SkuTableProps {
    orderFormId: number;
    disabled?: boolean;
    reload?: number;
}

export const SkuTable: React.FC<SkuTableProps> = ({ orderFormId, reload }) => {
    const [skus, setSkus] = useState([] as OrderFormSKU[]);
    const [editingKey, setEditingKey] = useState(0);
    const [saveCount, setSaveCount] = useState(0);
    const [form] = Form.useForm();
    const isEditing = (record: OrderFormSKU) => record.id === editingKey;
    const edit = (record: OrderFormSKU) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id!);
    };

    const doDelete = async (record: OrderFormSKU) => {
        await deleteSKU(record);
        setSaveCount(saveCount + 1);
    };
    const save = async (id: number) => {
        try {
            const row = (await form.validateFields()) as OrderFormSKU;

            await updateSKU({ ...row, orderFormId: orderFormId, id: id });
            setEditingKey(0);
            setSaveCount(saveCount + 1);
        } catch (errInfo) {
            handleError(errInfo);
        }
    };
    const cancel = async () => {
        setEditingKey(0);
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
            inputType: "string",
            width: 500,
            editable: true,
            render: (value: string, _row: any, index: number) => {
                const color = getSkuColor(index);

                return (
                    <Space>
                        <span
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                width: 16,
                                height: 16,
                                borderRadius: 4,
                                background: color,
                            }}
                        ></span>
                        {value}
                    </Space>
                );
            },
        },
        {
            title: "Sku",
            dataIndex: "sku",
            key: "sku",
            sorter: true,
            inputType: "string",
            width: 300,
            editable: true,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            sorter: true,
            inputType: "number",
            editable: true,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            sorter: true,
            inputType: "number",
            editable: true,
        },
        {
            title: "Total Price",
            dataIndex: "totalPrice",
            key: "totalPrice",
            sorter: true,
            inputType: "number",
            editable: true,
        },
        {
            title: "Actions",
            dataIndex: "operation",
            render: (_: any, record: OrderFormSKU) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <Typography.Link>Cancel</Typography.Link>
                        </Popconfirm>
                    </span>
                ) : (
                    <>
                        {" "}
                        <Typography.Link disabled={editingKey !== 0} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                        <Divider type="vertical" />
                        <Typography.Link
                            disabled={editingKey !== 0}
                            onClick={() => doDelete(record)}
                        >
                            Delete
                        </Typography.Link>
                    </>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: OrderFormSKU) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    useEffect(() => {
        const fn = async () => {
            const remoteSkus = await getSkus(orderFormId);
            setSkus(remoteSkus);
        };
        try {
            fn();
        } catch (error: any) {
            handleError(error);
        }
    }, [saveCount]);

    useEffect(() => {
        setSaveCount(saveCount + 1);
    }, [reload]);
    return (
        <div style={{ height: "350px" }}>
            <Form form={form} component={false}>
                <Table
                    size="small"
                    style={{ scrollbarWidth: "thin" }}
                    dataSource={skus}
                    columns={mergedColumns}
                    pagination={false}
                    scroll={{ y: 300 }}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                />
            </Form>
        </div>
    );
};
