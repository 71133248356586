import React from "react";
import { Col, Row, Space } from "antd";
import { roundToTwoDecimals } from "../../common/Number";
import { ReactComponent as MoneyIcon } from "../../assets/icons/svg/money.svg";

export interface NegotiationDealScoreProps {
    overallScore: number;
    overallGrade: string;
    averageScore: number;
}

export const NegotiationDealScore: React.FC<NegotiationDealScoreProps> = ({
    overallScore,
    overallGrade,
    averageScore,
}) => {
    return (
        <Row
            style={{
                borderRadius: 8,
                background: "#F0F8FF",
                padding: "12px 16px",
                marginTop: 20,
                borderBottom: "1px solid #99CEFF",
                color: "#404040",
                fontWeight: 500,
            }}
        >
            <Col flex={1} style={{ fontSize: 16, display: "flex", alignItems: "center" }}>
                <MoneyIcon width={20} height={20} fill="#5AD81D" style={{ marginRight: 8 }} />
                Deal scorecard
            </Col>
            <Col>
                <Space size={26}>
                    <div>
                        <span>Highest possible score: </span>
                        <span style={{ color: "#FF8400" }}>100%</span>
                    </div>
                    <div>
                        <span>Overall grade: </span>
                        <span style={{ color: "#FF8400" }}>
                            {overallScore}% ({overallGrade})
                        </span>
                        <span style={{ color: "#FF8400" }}> - Above average by </span>
                        <span style={{ color: "#FF8400" }}>
                            {roundToTwoDecimals(overallScore - averageScore)}%
                        </span>
                    </div>
                </Space>
            </Col>
        </Row>
    );
};
