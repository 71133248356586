import React, { CSSProperties, useEffect, useState } from "react";
import { Col, Row, Select, Space } from "antd";
import {
    getBudgetSetting,
    updateBudgetSetting,
    getCostData,
    getOrderExpirationYear,
} from "../../common/api/DashboardClientV2";
import {
    BudgetSetting,
    CumulativeOrderValue,
    FormattedCumulativeOrderValue,
    OrderExpirationYear,
} from "../../types/DashboardTypes";
import { CostChart, EventHandlerParams } from "./components/CostChart";
import "./CostChartPanel.css";
import { BudgetSettingModal } from "./components/BudgetSettingModal";
import { ComparisonSettingModal } from "./components/ComparisonSettingModal";

interface CostChartPanelProps {
    style?: CSSProperties;
}

export const CostChartPanel: React.FC<CostChartPanelProps> = ({ style }) => {
    const [data, setData] = useState([] as CumulativeOrderValue[]);
    const [showBudgetSettingModal, setShowBudgetSettingModal] = useState(false);
    const [showComparisonSettingModal, setShowComparisonSettingModal] = useState(false);
    const [settings, setSettings] = useState([] as BudgetSetting[]);
    const [settingsWithDefault, setSettingsWithDefault] = useState([] as BudgetSetting[]);
    const [settingToChange, setSettingToChange] = useState<BudgetSetting>();
    const [years, setYears] = useState<OrderExpirationYear[]>();
    const [selectedYear, setSelectedYear] = useState<number>();

    const currentYearSetting = settingsWithDefault.find((item) => item.year === selectedYear);
    const lastYearSetting = settingsWithDefault.find(
        (item) => item.year === Number(selectedYear) - 1
    );

    const allQuarterData = new Array(4).fill("").map((_, index) => {
        const quarterNumber = index + 1;
        const row = data.find((item) => item.quarter === quarterNumber);
        if (row) {
            return row;
        }
        return {
            orderCount: 0,
            cumulativeOrderCount: 0,
            quarter: quarterNumber,
            total: 0,
            cumulativeTotal: 0,
            week: quarterNumber,
            increase: 0,
            cumulativeIncrease: 0,
            decrease: 0,
            cumulativeDecrease: 0,
        } as CumulativeOrderValue;
    });

    const formattedData = [] as FormattedCumulativeOrderValue[];

    allQuarterData.forEach((item, index) => {
        const formatted = {
            ...item,
            xAxis: `Q${item.quarter}`,
            isQuarterEnd: false,
        };

        if (index === 0) {
            formattedData.push(formatted);
        } else {
            const preFormatted = formattedData[index - 1];
            formattedData.push({
                ...formatted,
                cumulativeTotal: item.total + preFormatted.cumulativeTotal,
                cumulativeOrderCount: item.orderCount + preFormatted.cumulativeOrderCount,
                cumulativeIncrease: item.increase + preFormatted.cumulativeIncrease,
                cumulativeDecrease: item.decrease + preFormatted.cumulativeDecrease,
            });
        }
    });

    const quarters = formattedData.map((item) => item.xAxis);

    const withChangeData = formattedData.map(
        (item) => item.cumulativeTotal - item.cumulativeDecrease
    );
    const currentData = formattedData.map((item) => item.cumulativeDecrease);
    const noActionData = formattedData.map((item) => item.cumulativeIncrease);

    const defaultLastYearBudget =
        Math.ceil(
            (noActionData[noActionData.length - 1] +
                currentData[currentData.length - 1] +
                withChangeData[withChangeData.length - 1]) /
                3
        ) || 0;

    const currentYearLabel = selectedYear ? selectedYear + "" : "";
    const lastYearLabel = selectedYear ? selectedYear - 1 + "" : "";

    const options = {
        xAxisLables: ["", ...quarters],
        withChangeData: [0, ...withChangeData],
        currentData: [0, ...currentData],
        noActionData: [0, ...noActionData],
        markLins: [
            { label: lastYearLabel, value: lastYearSetting?.budget || 0 },
            { label: currentYearLabel, value: currentYearSetting?.budget || 0 },
        ],
        quarterData: formattedData,
        decrease: currentYearSetting?.bestDecrease || 0,
        increase: currentYearSetting?.worstIncrease || 0,
    };

    const yearSelectOptions = years?.map((item) => {
        return { label: item.year, value: item.year };
    });

    const handleUpdate = async (params: BudgetSetting) => {
        await updateBudgetSetting(params as any);
        selectedYear && (await getBudget([selectedYear - 1, selectedYear]));

        setShowBudgetSettingModal(false);
        setShowComparisonSettingModal(false);
    };

    const refreshData = async (year: number) => {
        const res = await getCostData(year);
        setData(res);
    };

    const getBudget = async (years: number[]) => {
        const res = await getBudgetSetting(years);
        const newSettings = years.map((year) => {
            const raw = res.find((item) => item.year === year);
            if (raw) return raw;
            return {
                year,
                budget: 0,
                worstIncrease: 25,
                bestDecrease: 25,
            } as BudgetSetting;
        });
        setSettings(newSettings);
    };

    const getYearSelectOptions = async () => {
        const data = await getOrderExpirationYear();
        setYears(data);
        const defaultYear =
            data.find((item) => item.year === new Date().getFullYear())?.year || data[0]?.year;
        setSelectedYear(defaultYear);
    };

    const handleMarkLineClick = (params: EventHandlerParams) => {
        if (params.name === currentYearLabel) {
            setSettingToChange(currentYearSetting);
        } else if (params.name === lastYearLabel) {
            setSettingToChange(lastYearSetting);
        }
        setShowBudgetSettingModal(true);
    };

    useEffect(() => {
        getYearSelectOptions();
    }, []);

    useEffect(() => {
        const newSettings = settings.map((item) => {
            if (item.id) return item;
            return {
                ...item,
                budget:
                    item.year === selectedYear ? defaultLastYearBudget * 2 : defaultLastYearBudget,
            };
        });
        setSettingsWithDefault(newSettings);
    }, [defaultLastYearBudget, settings]);

    useEffect(() => {
        if (!selectedYear) return;
        getBudget([selectedYear - 1, selectedYear]);
        refreshData(selectedYear);
    }, [selectedYear]);

    return (
        <div style={style}>
            <Row align="middle" style={{ marginBottom: 12 }}>
                <Col flex={1} className="sectionTitle">
                    Summary
                </Col>
                <Space size={40}>
                    <span className="legend current">Current</span>
                    <span className="legend withChange">Projected renewal - With changes</span>
                    <span className="legend noAction">Projected renewal - No action</span>
                    <Space>
                        <span>Year:</span>
                        <Select
                            value={selectedYear}
                            options={yearSelectOptions}
                            style={{ width: 80 }}
                            onSelect={setSelectedYear}
                        ></Select>
                    </Space>
                </Space>
            </Row>
            <CostChart
                onMarkLineClick={handleMarkLineClick}
                onSeriesLineClick={() => setShowComparisonSettingModal(true)}
                {...options}
            />
            <BudgetSettingModal
                open={showBudgetSettingModal}
                budget={settingToChange}
                onCancel={() => setShowBudgetSettingModal(false)}
                onOk={handleUpdate}
            />
            <ComparisonSettingModal
                open={showComparisonSettingModal}
                budget={currentYearSetting}
                onCancel={() => setShowComparisonSettingModal(false)}
                onOk={handleUpdate}
            />
        </div>
    );
};
