import React, { createRef, useEffect, useState } from "react";
import { Button, DatePicker, Modal, Space, Table } from "antd";
import moment from "moment";
import { FieldTimeOutlined, SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Vendor } from "../../types/VendorTypes";
import { getVendors } from "../../common/api/VendorClient";
import { handleError } from "../../common/ErrorHandling";
import { OrderFormEndDates } from "../../types/NegotiationTypes";
import { formatDateString } from "../date/DateOp";

interface NegotiatioinEndDatesModalProps {
    orderForms: OrderFormEndDates[];
    visible: boolean;
    onCancel?: () => void;
}

type DataIndex = keyof OrderFormEndDates;

export const NegotiationEndDatesModal: React.FC<NegotiatioinEndDatesModalProps> = ({
    visible,
    orderForms,
    onCancel,
}) => {
    const [vendorsMap, setVendorsMap] = useState(new Map<string, Vendor>());
    const [loading, setLoading] = useState(true);
    const searchRef = createRef<any>();

    const refreshTable = async () => {
        try {
            setLoading(true);
            const vendorKeys = orderForms.map((v) => v.vendorKey);

            const orderVendors = await getVendors(Array.from(new Set(vendorKeys)));
            const vendorMap = new Map(orderVendors.map((v) => [v.vendorKey, v]));

            setVendorsMap(vendorMap);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };
    useEffect(() => {
        refreshTable();
    }, [orderForms]);

    const getTimeColumnProps = (dataIndex: DataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: FilterDropdownProps) => (
            <div style={{ padding: 8 }}>
                <div style={{ marginBottom: 8 }}>
                    <DatePicker.RangePicker
                        ref={searchRef}
                        onChange={(dates, _dateString) => {
                            if (dates) {
                                setSelectedKeys([dates as any]);
                            } else {
                                setSelectedKeys([]);
                            }
                        }}
                        placeholder={["start", "end"]}
                        value={selectedKeys[0] as any}
                        format="MM-DD-YYYY"
                    />
                </div>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: true });
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <FieldTimeOutlined type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value: any, row: OrderFormEndDates) =>
            moment(row[dataIndex]).isBetween(moment(value[0]), moment(value[1])),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchRef.current?.focus(), 100);
            }
        },
        render: (value: string) => formatDateString(value),
    });
    const columns = [
        {
            title: "Supplier name",
            dataIndex: "vendorKey",
            key: "vendorKey",
            render: (value: string) => {
                const vendor = vendorsMap.get(value);
                return vendor?.name;
            },
        },
        {
            title: "Expiration date",
            dataIndex: "expirationDate",
            key: "expirationDate",
            render: (value: string) => {
                return formatDateString(value);
            },
        },
        {
            title: "Quarter end",
            dataIndex: "quarterEnd",
            key: "quarterEnd",
            sorter: (a: OrderFormEndDates, b: OrderFormEndDates) =>
                new Date(a.quarterEnd).getTime() - new Date(b.quarterEnd).getTime(),
            ...getTimeColumnProps("quarterEnd"),
        },
        {
            title: "Year end",
            dataIndex: "yearEnd",
            key: "yearEnd",
            sorter: (a: OrderFormEndDates, b: OrderFormEndDates) =>
                new Date(a.yearEnd).getTime() - new Date(b.yearEnd).getTime(),
            ...getTimeColumnProps("yearEnd"),
        },
    ];

    return (
        <Modal
            visible={visible}
            title={`Strategic Renewal Planning – ${orderForms.length} Expiring deals`}
            onCancel={onCancel}
            footer={<Button onClick={onCancel}>Close</Button>}
            width={1200}
        >
            <Table
                dataSource={orderForms}
                loading={loading}
                columns={columns}
                pagination={{ position: orderForms.length > 10 ? ["bottomRight"] : [] }}
                rowKey="id"
                size="small"
            ></Table>
        </Modal>
    );
};
