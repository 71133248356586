import React from "react";
import { Link } from "react-router-dom";
import { SavingsResponse } from "../../types/ChatTypes";
import styles from "./SavingsChatMessage.module.less";

interface SavingsChatMessageProps extends SavingsResponse {}

export const SavingsChatMessage: React.FC<SavingsChatMessageProps> = ({
    orderForm,
    negotiation,
    supplier,
}) => {
    const negotiationPath = negotiation?.id
        ? `/app/negotiations/${negotiation.id}`
        : "/app/negotiations";
    const supplierPath = supplier?.id ? `/app/supplier/${supplier.id}` : "/app/supplier";
    return (
        <div className={styles.savings}>
            {orderForm ? (
                <>
                    <div>Found an expiring contract that have most opportunities for savings:</div>
                    <ul>
                        <li>
                            <Link to={`/app/order/${orderForm.id}`}>{orderForm.name}</Link>
                        </li>
                    </ul>
                    <div>You can negotiate the price down via:</div>
                    <div>
                        <ul>
                            <li>
                                <Link to={negotiationPath}>Negotiation simulation</Link>
                            </li>
                            <li>
                                <Link to={supplierPath}>Buyer briefs</Link>
                            </li>
                        </ul>
                    </div>
                </>
            ) : (
                "Sorry, no suitable contract was found."
            )}
        </div>
    );
};
