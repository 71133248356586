import { Popover, Calendar, Spin } from "antd";
import { CalendarMode } from "antd/lib/calendar/generateCalendar";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DashboardArguments, VendorSummary } from "../../common/DashboardInterfaces";
import { getDashboardData } from "../../common/api/DashboardClient";
import { DashboardVendorTimeSeries } from "../../types/OrderFormTypes";
import { getVendors } from "../../common/api/VendorClient";
import { extractUnit, TimeUnit } from "../../types/TimeTypes";
import { Vendor } from "../../types/VendorTypes";
import { VendorMiniPanel } from "../vendor/VendorMiniPanel";
import { OrderFormMiniPanel } from "../orderform/components/OrderFormMiniPanel";

export interface CalData {
    [key: string]: VendorSummary[];
}

export const CalendarDashboard: React.FC<DashboardArguments> = ({ vendors }) => {
    const [searchParams] = useSearchParams();
    const [calendarConfig, setCalendarConfig] = useState({
        date: moment(),
        timeUnit: searchParams.get("timeUnit") === TimeUnit.DAY ? TimeUnit.DAY : TimeUnit.MONTH,
    });
    const [calendarData, setCalendarData] = useState({
        data: [] as DashboardVendorTimeSeries[],
        vendors: [] as Vendor[],
        value: calendarConfig.date,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const unit = calendarConfig.timeUnit === TimeUnit.DAY ? "month" : "year";
        const dateStart = calendarConfig.date.clone().startOf(unit).toDate();
        const dateEnd = calendarConfig.date.clone().endOf(unit).toDate();
        getDashboardData(calendarConfig.timeUnit, vendors, dateStart, dateEnd)
            .then(async (data) => {
                const vendorIds = data.timeSeries
                    .map((s) => {
                        return s.values.map((v) => v.vendorKey);
                    })
                    .flat();
                const vendors = vendorIds.length > 0 ? await getVendors(vendorIds) : [];
                setCalendarData({
                    data: data.timeSeries,
                    vendors: vendors,
                    value: calendarConfig.date.clone(),
                });
            })
            .finally(() => setLoading(false));
    }, [vendors, calendarConfig]);

    const dateCellRender = (value: moment.Moment) => {
        if (calendarConfig.timeUnit === TimeUnit.MONTH) {
            if (calendarConfig.date.year() !== value.year()) {
                return;
            }
        } else if (calendarConfig.timeUnit === TimeUnit.DAY) {
            if (calendarConfig.date.month() !== value.month()) {
                return;
            }
        }
        const monthYear = value.format("MMMM YYYY");
        const unit = extractUnit(calendarConfig.timeUnit, value.toDate());
        const data = calendarData.data.filter((c) => c.unit === unit);
        if (data.length <= 0) {
            return <></>;
        }
        const items = data
            .map((uv) => uv.values)
            .flat()
            .map((vd) => {
                const vendor = calendarData.vendors.filter((v) => v.vendorKey === vd.vendorKey)[0];
                return (
                    <VendorMiniPanel
                        key={`calendar-row-${vendor.vendorKey}`}
                        vendor={vendor}
                        orders={vd.orderForms}
                    />
                );
            });

        const orderForms = data
            .map((uv) => uv.values)
            .flat()
            .map((vd) => vd.orderForms)
            .flat();
        const orderMiniPanel = (
            <OrderFormMiniPanel
                vendorKey={"dontcare"}
                title={`Orders for ${monthYear}`}
                orderForms={orderForms}
            />
        );
        return (
            <>
                <Popover content={orderMiniPanel} placement="topLeft">
                    {items}
                </Popover>
            </>
        );
    };
    return (
        <Spin spinning={loading}>
            <Calendar
                dateCellRender={dateCellRender}
                monthCellRender={dateCellRender}
                value={calendarData.value}
                onPanelChange={(date: moment.Moment, mode: CalendarMode) => {
                    setCalendarConfig({
                        date: date,
                        timeUnit: mode === "year" ? TimeUnit.MONTH : TimeUnit.DAY,
                    });
                }}
                mode={calendarConfig.timeUnit === TimeUnit.MONTH ? "year" : "month"}
            />
        </Spin>
    );
};
