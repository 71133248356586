import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "antd";
import NumberFormat from "react-number-format";
import { BudgetSetting } from "../../../types/DashboardTypes";

interface BudgetSettingModalProps {
    open: boolean;
    budget?: BudgetSetting;
    onCancel?: () => void;
    onOk?: (params: BudgetSetting) => void;
}

export const BudgetSettingModal: React.FC<BudgetSettingModalProps> = ({
    budget,
    open,
    onCancel,
    onOk,
}) => {
    const [form, setForm] = useState({} as BudgetSetting);

    useEffect(() => {
        if (!budget) return;
        setForm(budget);
    }, [budget]);

    return (
        <Modal
            title="Budget Setting"
            open={open}
            onCancel={onCancel}
            onOk={() => onOk?.(form)}
            okText="Confirm"
        >
            <Form>
                <Form.Item label={form.year}>
                    <NumberFormat
                        className={"ant-input"}
                        value={form.budget}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={"$"}
                        onValueChange={(value) => {
                            const newBudget = value.floatValue || 0;
                            setForm({ ...form, budget: newBudget });
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
